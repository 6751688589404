import React from "react";

import UserIcon from "@zeos/icons/UserIcon";
import LogoutIcon from "@zeos/icons/LogoutIcon";

import useItemIconStyles from "./ItemIcon.styles";

const Icons = {
  AvatarAIcon: UserIcon,
  DoorOpenIcon: LogoutIcon
};

export interface ItemIconProps {
  iconName: keyof typeof Icons;
  className?: string;
}

/**
 * The ItemIcon component takes in an iconName and returns an icon.
 *
 * @param {ItemIconProps} { iconName, className }
 * @returns {React.ReactElement | null}
 */
const ItemIcon = ({
  iconName,
  className = ""
}: ItemIconProps): React.ReactElement | null => {
  const { classes } = useItemIconStyles({});

  if (!iconName) {
    return null;
  }

  const Icon = Icons[iconName];

  return <Icon className={`${classes.itemIcon} ${className}`} />;
};

export default ItemIcon;
