import { convertRelativeTimeToServerTime } from "../utils";
import { ResourceEntry } from "./types";

/**
 * Determine resource span name based on type
 *
 * @param entry ResourceEntry
 */
export const determineEntrySpanName = (
  entry: Omit<ResourceEntry, "startTime" | "endTime">
): string => {
  const aliasMap: Record<string, string> = {
    intercom: "intercom",
    googletagmanager: "gtm",
    hotjar: "hotjar",
    sentry: "sentry",
    polyfill: "polyfill"
  };

  const regex = new RegExp(`(${Object.keys(aliasMap).join("|")})`);

  const matches = entry.url.match(regex);

  return `load_${
    (matches && matches[0] && aliasMap[matches[0]]) || entry.type
  }`;
};

/**
 * Normalizes performance entries for tracing
 *
 * The resulting entry list is used as a base type for all
 * performance tracing related functions
 *
 * Relative times in performance API are converted to
 * absolute times in micro seconds based on server time
 * for ease of use
 *
 * @param entries Array<PerformanceResourceTiming>
 * @return Array<ResourceEntry>
 */
export const normalizePerformanceEntries = (
  entries: Array<PerformanceResourceTiming>
): Array<ResourceEntry> => {
  return entries.map(entry => ({
    encodedSize: entry.encodedBodySize,
    endTime: convertRelativeTimeToServerTime(entry.responseEnd * 1000),
    decodedSize: entry.decodedBodySize,
    startTime: convertRelativeTimeToServerTime(entry.startTime * 1000),
    transferSize: entry.transferSize,
    type: entry.initiatorType,
    url: entry.name
  }));
};

/**
 * Sorts the entries to only include the supplied initiatorTypes,
 * and after the supplied mark (if provided)
 *
 * @param initiatorTypes Array<string>
 * @param afterMark string
 */
export const getSortedPerformanceEntries = (
  initiatorTypes: Array<string>,
  mark = ""
): Array<ResourceEntry> => {
  const entries = (
    window.performance.getEntries() as Array<PerformanceResourceTiming>
  ).sort((a, b) => a.startTime - b.startTime);

  const markIndex =
    Boolean(mark) && entries.map(entry => entry.name).lastIndexOf(mark);
  const relevantEntries =
    !markIndex || markIndex === -1 ? entries : entries.slice(markIndex);

  return normalizePerformanceEntries(
    relevantEntries.filter(entry =>
      initiatorTypes.includes(entry.initiatorType)
    )
  );
};
