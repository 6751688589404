import { AppConfig, PortalAppManifest } from "@zeos/platform";
import { Parcel } from "single-spa";
import { getPortalConfig } from "../portal-config";

interface ParcelData {
  /**
   * parcel config
   */
  config: AppConfig;

  /**
   * Parcel manfiest data
   */
  manifest?: PortalAppManifest;

  /**
   * Parcel single-spa object
   */
  singleSpaParcel?: Parcel;

  /**
   * If `true`, this means the parcel has been bootstrapped before
   */
  hasBeenBootstrapped?: boolean;

  /**
   * Initiator parcel
   */
  initiator: string;
}

class ParcelCache {
  /** Parcel cache */
  parcelCache: Record<string, ParcelData> = {};

  /**
   * Get parcel from cache or fallback to parcels from portal config if exists
   *
   * @param name Parcel name
   * @returns Parcel config
   */
  getParcelData = (name: string): ParcelData => {
    const parcelCachedApp = this.parcelCache[name];
    const portalAppConfig = getPortalConfig().parcels.find(
      item => item.name === name
    );

    if (!parcelCachedApp && !portalAppConfig) {
      throw new Error(
        `${name} is not found either in parcel cache nor portal config!`
      );
    }

    return (
      parcelCachedApp || {
        config: portalAppConfig
      }
    );
  };

  /**
   * Store parcel into cache
   *
   * @param name Parcel name
   * @param config Parcel config
   */
  setParcelData = (name: string, data: ParcelData): void => {
    this.parcelCache[name] = data;
  };

  /**
   * Set parcel hasBeenBootstrapped flag to true
   * so parcel will not be bootstrapped again
   *
   * @param name Parcel name
   */
  setParcelAsBootstrapped = (name: string): void => {
    if (!this.parcelCache[name]) {
      console.error(
        `[Error] Parcel ${name} can not be found in parcel cache to set as bootstrapped!`
      );
    }
    this.parcelCache[name].hasBeenBootstrapped = true;
  };
}

const parcelCache = new ParcelCache();

export default parcelCache;
