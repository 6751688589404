import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import type { Breadcrumb } from "@zeos/platform";

interface NavigationState {
  breadcrumbs: Breadcrumb[];
  showBackButton: boolean;
}

const initialState = {
  breadcrumbs: [],
  showBackButton: false
} satisfies NavigationState as NavigationState;

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    changeBreadcrumbs(state, { payload }: PayloadAction<NavigationState>) {
      state.breadcrumbs = payload.breadcrumbs;
      state.showBackButton = payload.showBackButton;
    },
    resetBreadcrumbs(state) {
      state.breadcrumbs = [];
      state.showBackButton = false;
    }
  }
});

export const { changeBreadcrumbs, resetBreadcrumbs } = navigationSlice.actions;
export default navigationSlice.reducer;
