import { format } from "date-fns";
import { enGB } from "date-fns/locale";

import Formatter from "./Formatter";

export interface DateFormatterOptions {
  style: string;
  notation: string;
}

export default class DateFormatter
  implements Formatter<Date, DateFormatterOptions>
{
  private locale = enGB;

  /**
   * Return full style of date by the template `EEEE, d MMMM yyyy` if notation 'standard'
   * otherwise `EEE, d MMM yyyy`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getFullFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "EEEE, d LLLL yyyy");
    }

    return this.formatLocale(value, "EEE, d LLL yyyy");
  }

  /**
   * Return long style of date by the template `d MMMM yyyy` if notation 'standard'
   * otherwise `d MMM yyyy`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getLongFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "d LLLL yyyy");
    }

    return this.formatLocale(value, "d LLL yyyy");
  }

  /**
   * Return medium (month) style of date by the template `MMMM yyyy` if notation 'standard'
   * otherwise `MMM yyyy`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getMediumFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "LLLL yyyy");
    }

    return this.formatLocale(value, "LLL yyyy");
  }

  /**
   * Return medium (day) style of date by the template `d MMMM` if notation 'standard' otherwise `d MMM`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getMediumDayFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "d LLLL");
    }

    return this.formatLocale(value, "d LLL");
  }

  /**
   * Returns short style of date by the template `dd/MM/yyyy` if notation 'standard' otherwise `dd/MM`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getShortFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "dd/LL/yyyy");
    }

    return this.formatLocale(value, "dd/LL");
  }

  /**
   * Returns time by the template `HH:mm:ss` if notation 'standard' otherwise `HH:mm`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getTimeFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "HH:mm:ss");
    }

    return this.formatLocale(value, "HH:mm");
  }

  /**
   * Returns time by the template `EEEE, d MMMM yyyy`, HH:mm:ss if notation 'standard'
   * otherwise `EEE, d MMM yyyy, HH:mm`
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  getDateTimeFormat(value: Date, options: DateFormatterOptions): string {
    if (options.notation === "standard") {
      return this.formatLocale(value, "EEEE, d LLLL yyyy, HH:mm:ss");
    }

    return this.formatLocale(value, "EEE, d LLL yyyy, HH:mm");
  }

  /**
   * Returns date string in required style
   * @param  {Date} value
   * @param  {DateFormatterOptions} options
   */
  format(value: Date, options: DateFormatterOptions): string {
    switch (options.style) {
      case "full":
        return this.getFullFormat(value, options);
      case "long":
        return this.getLongFormat(value, options);
      case "medium":
        return this.getMediumFormat(value, options);
      case "medium-day":
        return this.getMediumDayFormat(value, options);
      case "time":
        return this.getTimeFormat(value, options);
      case "date-time":
        return this.getDateTimeFormat(value, options);
      default:
        return this.getShortFormat(value, options);
    }
  }

  private formatLocale(value: Date, pattern: string) {
    return format(value, pattern, {
      locale: this.locale
    });
  }
}
