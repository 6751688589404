import { LifeCycleFn } from "single-spa";

export interface PhaseAction {
  /**
   * Type of action
   */
  type: string;
  payload: {
    /**
     * App name
     */
    appName: string;
    /**
     * App label
     */
    appLabel: string;
    /**
     * Error object
     */
    error?: Error;
  };
}

export interface PhaseHook {
  /**
   * Phase start is triggered before the lifecycle hook is being executed
   *
   * @param phase Phase name
   * @param appName Module name
   * @param phaseProps Hook props object
   */
  start?: (phase: string, appName: string, phaseProps: any) => Promise<void>;

  /**
   * Phase success is triggered after the lifecycle hook is executed successfully
   *
   * @param phase Phase name
   * @param appName Module name
   * @param phaseProps Hook props object
   */
  success?: (phase: string, appName: string, phaseProps: any) => Promise<void>;

  /**
   * Phase error is triggered in case of an error occurred in the lifecycle hook
   *
   * @param phase Phase name
   * @param appName Module name
   * @param phaseProps Hook props object
   * @param error Error object
   */
  error?: (
    phase: string,
    appName: string,
    phaseProps: any,
    error: Error
  ) => Promise<void>;
}

export const decorateWithHooks =
  <T>(
    phase: string,
    appName: string,
    func: LifeCycleFn<T>,
    hooks: PhaseHook[] = []
  ): LifeCycleFn<T> =>
  async phaseProps => {
    // [Important Note]: pass the phaseProps reference object to hook start
    const startHooks = hooks
      .filter(hook => Boolean(hook.start))
      .map(hook => hook.start?.(phase, appName, phaseProps));
    await Promise.all(startHooks);

    try {
      const value = await func(phaseProps);
      const successHooks = hooks
        .filter(hook => Boolean(hook.success))
        .map(hook => hook.success?.(phase, appName, phaseProps));
      await Promise.all(successHooks);
      return value;
    } catch (e) {
      const errorHooks = hooks
        .filter(hook => Boolean(hook.error))
        .map(hook => hook.error?.(phase, appName, phaseProps, e as Error));
      await Promise.all(errorHooks);

      throw e;
    }
  };
