import { makeStylesWithClassOverrides as makeStyles } from "@zeos/theme";

const useLegalDialogContainerStyles = makeStyles({ name: "DialogContainer" })(
  () => ({
    paperWidthLg: {
      maxWidth: 1448,
      maxHeight: "80%"
    }
  })
);

const useLegalDialogContentStyles = makeStyles({ name: "DialogContent" })(
  thene => ({
    root: {
      padding: `${thene.spacing(0)} ${thene.spacing(5)}`,
      color: "#4D4E50",
      margin: "0 32px 24px 32px",
      "& h2, & h3, & strong": {
        color: "#1a1a1a"
      },
      "& li": {
        marginLeft: -20
      },
      "& ol ol li": {
        marginTop: 5,
        marginLeft: -40
      },
      "& ol ol li ul": {
        marginTop: 5,
        marginLeft: 40
      },
      "& ol ol ol li": {
        marginTop: 5,
        marginLeft: -20
      },
      "& ol": {
        counterReset: "item"
      },
      "& ol > li": {
        counterIncrement: "item"
      },
      "& ol ol": {
        listStyle: "none"
      },
      "& ol ol > li::before": {
        content: 'counters(item, ".") ". "'
      }
    }
  })
);

const useDialogTabsStyles = makeStyles({ name: "DialogTabs" })(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export const useLegalDialogStyles = (): {
  dialog: Record<string, string>;
  content: Record<string, string>;
  tabs: Record<string, string>;
} => {
  const dialog = useLegalDialogContainerStyles({});
  const content = useLegalDialogContentStyles({});
  const tabs = useDialogTabsStyles({});
  return {
    dialog: dialog.classes,
    content: content.classes,
    tabs: tabs.classes
  };
};
