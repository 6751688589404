import Formatter from "./Formatter";
import { formatCompact } from "./utils";

export interface NumberFormatterOptions {
  precision?: number;
  compact?: boolean;
}

export default class NumberFormatter
  implements Formatter<number, NumberFormatterOptions>
{
  private localization = "en-GB";
  private MAX_PRECISION = 4;

  /**
   * Returns number of fractional digitals for the compact option.
   *
   * @param  {number} value
   * @param  {NumberFormatterOptions} options
   * @returns number
   */
  private getNumberFractionalDigitals(
    value: number,
    options: NumberFormatterOptions
  ): number {
    const { compact } = options;

    if (compact) {
      return value < 1000 ? 0 : 1;
    }

    return 0;
  }

  /**
   * Formats numbers based on compact option or required precision.
   * If precision is not set returns number itself.
   * @param  {number} value
   * @param  {NumberFormatterOptions} options
   * @returns string
   */
  format(value: number, options: NumberFormatterOptions): string {
    const precisionOptions = this.getPrecisionOptions(value, options.precision);
    if (options.compact) {
      const numberFractionalDigitals = this.getNumberFractionalDigitals(
        value,
        options
      );
      return formatCompact(value, {
        fractionalDigitals: numberFractionalDigitals
      });
    }
    return new Intl.NumberFormat(this.localization, {
      ...precisionOptions
    }).format(value);
  }

  /**
   * Returns precision and depends on the value and precision option.
   *
   * @param  {number} value
   * @param  {number} precision
   */
  getPrecisionOptions(
    value: number,
    precision?: number
  ): {
    maximumFractionDigits: number;
    minimumFractionDigits: number;
  } {
    const fractionLength = value.toString().replace(/.*\./, "").length;
    let minimumFractionDigits = fractionLength;
    let maximumFractionDigits = fractionLength;

    if (precision != null) {
      const maxFractionDigits =
        precision > this.MAX_PRECISION ? this.MAX_PRECISION : precision;
      minimumFractionDigits = maxFractionDigits;
      maximumFractionDigits = maxFractionDigits;
    }

    return {
      minimumFractionDigits,
      maximumFractionDigits
    };
  }
}
