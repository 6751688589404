import React from "react";
import { sentry } from "@zeos/platform";

import { openParcel } from "../core/parcels";

interface ParcelProps {
  parcelId: string;
  elementId: string;
}

const Parcel = ({ parcelId, elementId }: ParcelProps): React.ReactElement => {
  const parcelRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const loadParcel = async () => {
      if (!parcelRef.current) return;
      try {
        await openParcel(parcelId, {
          root: parcelRef.current,
          initiator: "zeos-web-portal"
        });
      } catch (e) {
        sentry.captureError(e as Error);
      }
    };

    loadParcel();
  }, []);

  return <div ref={parcelRef} id={elementId} />;
};

export default Parcel;
