/**
 * Sanitize the string by escaping the following characters with HTM
 * entity encoding to prevent switching into any execution context
 * https://cheatsheetseries.owasp.org/cheatsheets/Cross_Site_Scripting_Prevention_Cheat_Sheet.html#rule-1---html-escape-before-inserting-untrusted-data-into-html-element-content
 * @param {string} string - string that used to sanitize
 */
export const sanitize = (string: string): string => {
  if (typeof string !== "string") {
    return string;
  }

  const map: any = {
    "<": "&lt;",
    ">": "&gt;",
    '"': "&quot;",
    "'": "&#x27;",
    "/": "&#x2F;"
  };
  const reg = /[<>"'/]/gi;
  return string.replace(reg, match => map[match]);
};
