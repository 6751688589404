import { AppConfig } from "@zeos/platform";

const PREFERRED_REDIRECT_APPS = ["reporting", "article-validation"];

const getDefaultRedirectPath = () => {
  const reportingPage = window.PortalConfig?.apps?.find(({ name }: AppConfig) =>
    PREFERRED_REDIRECT_APPS.includes(name)
  );
  return reportingPage
    ? reportingPage?.pathPrefix
    : window.PortalConfig?.apps?.[0]?.pathPrefix;
};

export const redirectHandler = () => {
  const currentPath = window.location.pathname;
  const defaultRedirect = getDefaultRedirectPath();
  const sessionRedirect = sessionStorage.getItem("redirectUrl");

  if (window.location.pathname === "/login/callback") {
    sessionStorage.removeItem("redirectUrl");
    const newLocation = sessionRedirect ?? defaultRedirect ?? "/";
    history.replaceState(null, "", newLocation);
    return;
  }

  if (currentPath === "/" && defaultRedirect !== currentPath) {
    history.replaceState(null, "", defaultRedirect);
  }
};
