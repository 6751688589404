import React, { useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";

import Dialog from "@zeos/components/Dialog";
import DialogTitle from "@zeos/components/DialogTitle";
import DialogContent from "@zeos/components/DialogContent";
import Tabs from "@zeos/components/Tabs";
import Tab from "@zeos/components/Tab";
import IconButton from "@zeos/components/IconButton";
import DialogContentText from "@zeos/components/DialogContentText";
import CloseIcon from "@zeos/icons/CloseIcon";

import { LegalContentName } from "./LegalDialog.types";
import { useLegalDialogStyles } from "./LegalDialog.styles";

import { tabs } from "./legalData";

interface LegalDialogProps {
  onClose: React.MouseEventHandler;
  open: boolean;
}

const LegalDialog = ({
  onClose,
  open
}: LegalDialogProps): React.ReactElement => {
  const [tabValue, setTabValue] = useState("terms-and-conditions");
  const paperRef = useRef<HTMLDivElement>(null);
  const classes = useLegalDialogStyles();
  const { t } = useTranslation();

  const handleTabChange = (
    _: React.ChangeEvent<any>,
    newValue: LegalContentName
  ): void => {
    setTabValue(newValue);
    paperRef.current!.scrollTop = 0;
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      aria-labelledby="Legal information"
      classes={classes.dialog}
      maxWidth="lg"
      scroll="paper"
      fullWidth
    >
      <DialogTitle>
        <span>{t("NAVIGATION_BAR.USER_PROFILE.LEGAL_INFORMATION.LABEL")}</span>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <div className={classes.tabs.root}>
        <Tabs value={tabValue} onChange={handleTabChange}>
          {Object.keys(tabs).map(tabId => (
            <Tab
              key={tabId}
              label={tabs[tabId].label}
              value={tabId}
              data-testid={tabId}
            />
          ))}
        </Tabs>
      </div>
      <DialogContent
        key="legalContent"
        ref={paperRef}
        classes={classes.content}
        data-testid="legal-content-paper"
      >
        <DialogContentText
          data-testid="legal-content"
          component="div"
          color="inherit"
        >
          <ReactMarkdown>{tabs[tabValue].content}</ReactMarkdown>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LegalDialog;
