import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import Loader from "@zeos/components/Loader";
import MenuItem from "@zeos/components/MenuItem";
import Typography from "@zeos/components/Typography";
import { useLoadMoreAccountsStyles } from "./LoadMoreAccounts.styles";

interface Props {
  isLoading: boolean;
  isMoreAvailable: boolean;
  loadMore: () => void;
}

export const LoadMoreAccounts: FC<Props> = ({
  isLoading,
  isMoreAvailable,
  loadMore
}) => {
  const { t } = useTranslation();
  const { classes } = useLoadMoreAccountsStyles();

  if (!isLoading && !isMoreAvailable) {
    return null;
  }

  const i18nKey = isLoading
    ? ["NAVIGATION_BAR.USER_PROFILE.ACCOUNT_SWITCH.LOADING"]
    : ["NAVIGATION_BAR.USER_PROFILE.ACCOUNT_SWITCH.LOAD_MORE"];

  return (
    <Loader loading={isLoading} className={classes.bottomSection}>
      <MenuItem
        onClick={isMoreAvailable ? loadMore : undefined}
        className={classes.loadMoreButton}
      >
        <Typography variant="h6">{t(i18nKey)}</Typography>
      </MenuItem>
    </Loader>
  );
};
