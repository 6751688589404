import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";
import { getServerSpanContext } from "./ObjectPropagator";
import { convertRelativeTimeToServerTime } from "./utils";
import { COMMON_TAGS } from "core/monitoring/tracing/tag-utils";

interface GlobalSpans {
  serverSpan?: SpanImp;
}

export const globalSpans: GlobalSpans = {
  serverSpan: undefined
};

/**
 * Creates new span for tracing bootstrap by using {tracer} in params as a child
 * of the {parent} span
 * @param tracer Tracer
 * @param parent SpanImp
 */
export const getServerSpan = (tracer: Tracer): SpanImp => {
  if (globalSpans.serverSpan) {
    return globalSpans.serverSpan;
  }

  /**
   * We can only send the span context from server to client
   * and typically OpenTracing supports adding the context as a
   * parent of a span. However, in  our case, we need to have the
   * started server span itself to be able to close it in the client.
   * That's why we need to recreate this span in client using the correct
   * start time and context (spanID and traceId). We already send these
   * values in PortalConfig. Additionally, we also inject all the tags of the
   * server span as OpenTracing baggage; so that, we can add
   * it to the recreated client span.
   */

  // Set start time
  globalSpans.serverSpan = tracer.startSpan("render_portal", {
    startTime: convertRelativeTimeToServerTime(0),
    childOf: getServerSpanContext("rootSpan")
  }) as SpanImp;

  const context = getServerSpanContext("portalSpan");

  // Set span context
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  globalSpans.serverSpan._ctx = context;

  // Set Tags
  context?.forEachBaggageItem((key, value) => {
    globalSpans.serverSpan?.setTag(key, value);
  });

  globalSpans.serverSpan.setTag(COMMON_TAGS.CONSENT, "true");

  return globalSpans.serverSpan;
};

/**
 * @only For tests
 */
export const resetServerSpan = (): void => {
  globalSpans.serverSpan = undefined;
};
