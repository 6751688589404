import { makeStyles } from "@zeos/theme";

export const useAccountPickerStyles = makeStyles({ name: "AccountPicker" })(
  theme => ({
    picker: {
      "& > label": {
        display: "none"
      },
      "& > .MuiInputBase-root": {
        "& fieldset, &:not(.Mui-error):not(.Mui-focused):not(.Mui-disabled):hover .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "transparent"
          },
        "& > .MuiOutlinedInput-input > div": {
          color: theme.palette.semantic.text.primary,
          fontSize: 14,
          fontWeight: 700
        }
      }
    }
  })
);
