import { getPortalConfig, NavigationItemConfig } from "core/portal-config";
import {
  NavigationBarNavOptionItem,
  NavigationBarNavOptions
} from "../core/app-config";
import { appMenuIconMapping } from "./app-icons";

// copy from ZDirect

/**
 * map the navigation configuration item to navigation UI item type
 * @param item navigation configuration item
 */
export const mapNavigationOptionItem = ({
  href: link,
  icon: iconName,
  children,
  ...item
}: NavigationItemConfig): NavigationBarNavOptionItem => ({
  ...item,
  link,
  iconName,
  icon: appMenuIconMapping[iconName as keyof typeof appMenuIconMapping],
  children: children?.map(child => mapNavigationOptionItem(child))
});

/**
 * This function extracts the navigation from portal config and map
 * the navigation configuration items
 * and map their items & children to NavigationBarNavOptions type
 */
export const getAllowedNavOptions = (): NavigationBarNavOptions => {
  const { navigation } = getPortalConfig();

  const tabs = Array.isArray(navigation?.tabs)
    ? navigation.tabs.map(mapNavigationOptionItem)
    : [];

  return { tabs };
};
