import React from "react";
import { Provider } from "react-redux";

import { ZeosThemeProvider } from "@zeos/theme";
import { withErrorBoundary } from "common-components/withErrorBoundary";

import store from "core/store";

import { AppStatus } from "./components";

/**
 * Status template
 *
 * Renders app status to render loading while
 * loading portal or errors if occurred
 */
export const StatusTemplate = (): React.ReactElement => (
  <Provider store={store}>
    <ZeosThemeProvider>
      <AppStatus />
    </ZeosThemeProvider>
  </Provider>
);

export default withErrorBoundary(StatusTemplate);
