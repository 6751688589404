import { makeStyles } from "@zeos/theme";

export const useDropdownHeaderStyles = makeStyles({ name: "DropdownHeader" })(
  theme => ({
    profileInfo: {
      marginBottom: 2,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 214,
      lineHeight: 1
    },
    emailRoleStyle: {
      color: theme.palette.semantic.text.body
    },
    profileNameEmail: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(1)
    }
  })
);

export const useDropdownMenuOptionsStyles = makeStyles({
  name: "DropdownMenuOptions"
})(() => ({
  listMenuItem: {
    alignItems: "center !important"
  }
}));

export const useDropdownFooterStyles = makeStyles({ name: "DropdownFooter" })(
  theme => ({
    dividerWrapper: {
      display: "flex",
      flexDirection: "column",
      paddingTop: theme.spacing(3),
      flexGrow: 1,
      gap: theme.spacing(2),
      "&:before": {
        content: '""',
        width: "100%",
        height: 1,
        backgroundColor: theme.palette.semantic.background.border,
        position: "absolute",
        top: 0,
        left: 0
      }
    },
    footerOptionWrapper: {
      display: "flex",
      maxWidth: 130,
      cursor: "pointer"
    },
    footerText: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: 214
    }
  })
);
