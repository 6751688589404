import { AppConfig, PortalAppManifest } from "@zeos/platform";

export async function loadAppManifest(
  appConfig: AppConfig
): Promise<PortalAppManifest> {
  try {
    const res = await fetch(appConfig.url);
    return (await res.json()) as PortalAppManifest;
  } catch (e: unknown) {
    if (e instanceof Error) {
      console.error(
        `Failed to fetch "${appConfig.name}" app manifest file at ${appConfig.url}, caused by ${e.message}`
      );
    }
    throw e;
  }
}

/**
 * Check whether a portal app manifest has the minimal set of value
 * required to let the portal app to be loaded correctly
 *
 * @param {PortalAppManifest} appManifest
 */
export function isValidAppManifest(appManifest: PortalAppManifest): boolean {
  return (
    !!appManifest.moduleName && !!appManifest.files && !!appManifest.files.js
  );
}
