/**
 * This schema uses category as key
 * and actions as array of types
 *
 * This is necessary for generating proper
 * Typescript types
 *
 * Note: casting this object to const is necessary
 * for Typescript casting to work properly
 */
const internalTrackingEventSchema = {
  Icon: ["Clicked"],
  Table: ["Expanded", "Collapsed", "Added", "Deleted"],
  File: ["Triggered"],
  Stepper: ["Clicked"],
  Button: ["Clicked"],
  Navigation: ["Clicked"],
  zCard: ["Clicked"],
  Widget: ["Clicked", "Hovered", "Selected", "Error"],
  DatePicker: ["Selected"],
  Link: ["Clicked"],
  Form: ["Submitted"],
  Filter: [
    "Selected",
    "Selected All",
    "Cleared All",
    "Group Expanded",
    "Group Collapsed"
  ],
  Dropdown: ["Selected", "Selected All", "Cleared All"],
  Checkbox: ["Selected"],
  Switch: ["Selected"],
  Radio: ["Selected"],
  TextField: ["Filled"],
  Search: ["Triggered"]
} as const;

/**
 * This schema converts the real tracking schema
 * to a nicer type that provides better user experience
 *
 * Note: When adding types, always update `internalTrackingEventSchema`
 */
export const trackingEventSchema = internalTrackingEventSchema as Record<
  string,
  readonly string[]
>;
/**
 * This TS type converts arrays in event schema
 * to unions.
 *
 * Example,
 *
 * Table: ["Expanded", "Collapsed"] -> Table: "Expanded" | "Collapsed"
 */
export type TrackingEventSchema = {
  [K in keyof typeof internalTrackingEventSchema]: (typeof internalTrackingEventSchema)[K][number];
};

/**
 * This TS type returns event categories as union
 */
export type TrackingEventCategory = keyof TrackingEventSchema;
