import React, { FC } from "react";
import { getMe } from "@zeos/platform";
import LegalDialog from "./LegalDialog/LegalDialog";
import Box from "@zeos/components/Box";
import UserProfile from "./UserProfile";
import { AccountPicker } from "./AccountPicker";
import { isConsentActive } from "../core/trackers/consent";
import { NOTIFICATION_PARCEL } from "../constants/portal-parcels";
import Parcel from "../components/Parcel";

interface Props {
  isAccountSelectAllowed: boolean;
}

// modified copy from ZDirect's NavBarAccount.tsx
const NavBarAccount: FC<Props> = ({
  isAccountSelectAllowed
}): React.ReactElement | null => {
  const [legalOpen, setLegalOpen] = React.useState<boolean>(false);
  const currentUser = getMe();
  const shouldShowAccountPicker =
    currentUser?.has_multiple_accounts && isAccountSelectAllowed;

  if (!currentUser) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" gap={3}>
      {shouldShowAccountPicker && <AccountPicker currentUser={currentUser} />}
      <Parcel
        parcelId={NOTIFICATION_PARCEL}
        elementId={`${NOTIFICATION_PARCEL}_id`}
      />
      <UserProfile
        me={currentUser}
        consent={isConsentActive()}
        handleLegalClick={() => {
          setLegalOpen(true);
        }}
      />
      <LegalDialog open={legalOpen} onClose={() => setLegalOpen(false)} />
    </Box>
  );
};

export default NavBarAccount;
