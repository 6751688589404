import { Me, PortalApp, init as sdkInit } from "@zeos/platform";

import store from "core/store";
import { getTracer } from "core/monitoring/tracing";
import { trackingEventSchema } from "core/tracking-schema";

/**
 * Initializes the SDK for web portal
 *
 * @param portalConfig Portal Configuration
 * @param me Me object
 * @param app Web Portal Manifest info. This value is generated on the spot
 * @param eventBus Pub/Sub event bus
 * @param parcels Parcels Interface
 */
export const initializeSDK = (me: Me, app: PortalApp): void => {
  sdkInit({
    context: {
      me,
      store,
      app
    },
    tracingContext: {
      tracer: getTracer("web-portal")
    },
    trackingEventSchema
  });
};
