import { AppConfig } from "@zeos/platform";

export type NavigationTree = {
  [pathSegment: string]: {
    appName?: string;
    children?: NavigationTree;
  };
};

/**
 * Transforms the app config into a tree like structure
 * Where the appName is the key and the value is 1 - N apps
 * defined underneath the url path
 * @param appsConfigs
 */
export function createNavigationTree(
  appsConfigs: Array<AppConfig>
): NavigationTree {
  const tree = {};
  /**
   * TODO: once this ticket is merged: https://github.bus.zalan.do/merchant-platform/quasar-overview/issues/1420
   *       use getAppsWithPathPrefix from "util/apps" instead of `filter` to remove code redundancy
   */
  appsConfigs
    .filter(app => Boolean(app.pathPrefix))
    .forEach(({ pathPrefix, name }) => {
      const pathSegments = pathPrefix.split("/");
      pathSegments.shift();
      setNavigationBranch(pathSegments, tree, name);
    });
  return tree;
}

/**
 * Changes the root node adding the segments in a tree
 * structure.
 * @param pathSegments list of segments in a path
 * @param tree the input tree root node where the segments will be placed
 * @param appName the application name
 */
function setNavigationBranch(
  pathSegments: Array<string>,
  tree: NavigationTree,
  appName: string
): void {
  const segments = [...pathSegments];
  let lastNode: NavigationTree = tree;
  while (segments.length > 0) {
    const segment = segments.shift() as string;
    if (segments.length > 0) {
      const node = lastNode[segment] ?? { children: {} };
      lastNode[segment] = node;
      node.children = node.children ?? {};
      lastNode = node.children;
    } else {
      const node = lastNode[segment] ?? {};
      lastNode[segment] = node;
      node.appName = appName;
    }
  }
}

/**
 * Get the active appName based on the path
 * The most specific match will take precedence
 * Recursively traverses the pathSegments
 * @param pathSegments eg) /segment1/segment2
 * @param tree
 */
export function getAppNameByPathSegments(
  pathSegments: Array<string>,
  tree: NavigationTree
): string | undefined {
  const segments = [...pathSegments];
  let lastNode: NavigationTree = tree;
  let lastAppName: string | undefined = undefined;
  while (segments.length > 0 && lastNode) {
    const segment = segments.shift() as string;
    const node = lastNode[segment];
    if (node?.appName) {
      lastAppName = node.appName;
    }
    if (!node?.children) {
      break;
    }
    lastNode = node.children;
  }
  return lastAppName;
}
