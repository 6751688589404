import { makeStyles, type Theme } from "@zeos/theme";

export const useStyles = makeStyles({ name: "SnackbarList" })(
  (theme: Theme) => ({
    snackCenter: {
      bottom: theme.spacing(3)
    },
    snackBar: {
      position: "initial",
      margin: theme.spacing(2),
      maxWidth: "none",
      "& .MuiSnackbarContent-message": {
        width: "100%"
      }
    },
    snackBarList: {
      position: "fixed",
      zIndex: 99999,
      bottom: theme.spacing(2)
    },
    leftSnackBarList: {
      left: theme.spacing(2)
    },
    rightSnackBarList: {
      right: theme.spacing(2)
    }
  })
);
