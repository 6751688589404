import React, { useMemo, useRef, useState } from "react";
import { type Me } from "@zeos/platform";
import Menu from "@zeos/components/Menu";
import { AvatarButton } from "./AvatarButton";
import { Dropdown } from "./Dropdown";
import { useUserProfileStyles } from "./UserProfile.styles";
import {
  getDefaultDropdownOptions,
  getFooterOptions,
  getUserProfile
} from "./user-profile-utils";

const getInitials = (name: string) => {
  const initials = name
    .split(" ")
    .map(word => word[0])
    .slice(0, 2)
    .join("");
  return initials;
};

const DEFAULT_USER = {
  principal_user_id: "",
  account_id: "",
  has_multiple_accounts: false,
  account_name: "",
  account_type: "",
  bpids: [],
  client_id: "",
  display_name: "",
  groups: [],
  principal_user_email: "",
  role_display_name: "",
  scopes: [],
  user_id: "",
  user_type: "developer",
  username: ""
} as Me;

/**
 * The UserProfile component provides the user with a dropdown menu that contains the user's information and a list of options that the user can select.
 *
 * @param { dropdownOptions, userProfile, footerOptions }
 *
 * @returns {React.ReactElement}
 */
function UserProfile({
  me = DEFAULT_USER,
  consent,
  handleLegalClick
}: {
  me: Me;
  consent: boolean;
  handleLegalClick: () => void;
}): React.ReactElement {
  const iconRef = useRef<HTMLDivElement>(null);
  const initials = getInitials(me?.display_name);
  const [showDropdown, setShowDropdown] = useState(false);
  const { classes } = useUserProfileStyles({});

  const userProfile = useMemo(() => getUserProfile(me), [me]);

  const footerOptions = useMemo(
    () => getFooterOptions(consent, handleLegalClick),
    [consent, handleLegalClick]
  );

  const defaultMenuOptions = useMemo(() => getDefaultDropdownOptions(), []);

  function handleClick() {
    setShowDropdown(!showDropdown);
  }

  function handleClose() {
    setShowDropdown(false);
  }

  return (
    <>
      <AvatarButton
        ref={iconRef}
        handleClick={handleClick}
        initials={initials}
      />
      <Menu
        anchorEl={iconRef.current}
        onClose={handleClose}
        open={showDropdown}
        disableAutoFocusItem
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        classes={{ list: classes.wrapper }}
      >
        <Dropdown
          userProfile={userProfile}
          defaultMenuOptions={defaultMenuOptions}
          footerOptions={footerOptions}
          handleClose={handleClose}
        />
      </Menu>
    </>
  );
}

export default UserProfile;
