import React, { FC, useEffect, useMemo, useState } from "react";
import { type Me } from "@zeos/platform";
import { useTranslation } from "react-i18next";
import Select from "@zeos/components/Select";
import { useAvailableAccounts } from "../../hooks/useAccounts";
import { LoadMoreAccounts } from "./LoadMoreAccounts";
import { useAccountPickerStyles } from "./AccountPicker.styles";

interface Props {
  currentUser: Me;
}

export const AccountPicker: FC<Props> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { classes } = useAccountPickerStyles();
  const availableAccounts = useAvailableAccounts(currentUser.principal_user_id);
  const [localSearch, setLocalSearch] = useState<string>("");

  useEffect(() => {
    availableAccounts.search(localSearch?.toLowerCase() ?? "");
  }, [localSearch]);

  const availableAccountsOptions = useMemo(() => {
    // Always show the current account on the top of the list
    const dropdownOptions = [
      {
        label: currentUser.account_name.replace(/ - zeos$/, ""),
        value: currentUser.account_id,
        disabled: false,
        selected: true
      }
    ];

    // Add other loaded accounts, but without duplicating the current one
    // can we have multiple accounts with the same id (but different role)?
    availableAccounts.data.forEach(account => {
      if (account.id !== currentUser.account_id) {
        dropdownOptions.push({
          label: account.name.replace(/ - zeos$/, ""),
          value: account.id,
          disabled: !account.enabled,
          selected: false
        });
      }
    });

    return dropdownOptions;
  }, [availableAccounts.data]);

  return (
    <Select
      search
      className={classes.picker}
      label={t("NAVIGATION_BAR.USER_PROFILE.ACCOUNT_SWITCH.LABEL")}
      options={availableAccountsOptions}
      value={currentUser.account_id}
      multiple={false}
      onChange={(accountId: string) =>
        availableAccounts.setActiveAccount(accountId)
      }
      filterFunc={(option, searchTerm: string) => {
        if (searchTerm !== localSearch) {
          setLocalSearch(searchTerm?.toLowerCase() ?? "");
        }
        return true;
      }}
      onOpen={() => setLocalSearch("")}
      menuBottom={
        <LoadMoreAccounts
          isLoading={availableAccounts.isPending}
          isMoreAvailable={availableAccounts.areMoreAccountsAvailable}
          loadMore={availableAccounts.loadMore}
        />
      }
    />
  );
};
