import { sentryConfigEnabled } from "core/trackers/tracker-status";

import { initSentry } from "@zeos/platform";
import { SentryConfig } from "../portal-config";

export const startSentryForPortal = (sentryConfig: SentryConfig) => {
  if (!sentryConfig || !sentryConfigEnabled(sentryConfig)) {
    return;
  }

  initSentry({
    dsn: sentryConfig.dsn,
    enableGlobalIntegrations: true,
    release: sentryConfig.release || undefined,
    minSeverity: sentryConfig.minSeverity || "error",
    debug: sentryConfig.debug
  });
};
