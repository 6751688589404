import React from "react";
import { useSelector } from "react-redux";

import { RootState } from "core/store";

import { ErrorFactory } from "../ErrorFactory/ErrorFactory";

export const AppStatus = (): React.ReactElement => {
  const appStatus = useSelector((state: RootState) => state.appStatus);

  return (
    <div>
      {appStatus.error && <ErrorFactory error={appStatus.error} />}
    </div>
  );
};
