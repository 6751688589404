import React from "react";
import Typography from "@zeos/components/Typography";
import Tooltip from "@zeos/components/Tooltip";
import { useDropdownHeaderStyles } from "./Dropdown.styles";
import { UserProfile } from "./Dropdown.types";

type DropdownHeaderProps = {
  userProfile: UserProfile;
};

/**
 * Renders the user profile information
 * @param {DropdownHeaderProps["userProfile"]} userProfile - An object that could contain name, email and role
 * @param {string} userProfile.email - The email of the user
 * @param {string | Array<string>} userProfile.role - The role (or roles) of the user. It could be a single string or an array of strings.
 * @param {string} userProfile.name - The name of the user
 *
 * @returns {React.ReactElement}
 */
export function DropdownHeader({
  userProfile
}: DropdownHeaderProps): React.ReactElement {
  const { email, role, name } = userProfile;
  const { classes, cx } = useDropdownHeaderStyles();

  function renderRole(role?: string | Array<string>): React.ReactNode {
    if (!role) {
      return null;
    }

    if (typeof role === "string") {
      return role;
    }

    if (Array.isArray(role)) {
      return `${role[0]} ${role.length > 1 ? `+${role.length - 1}` : ""}`;
    }
  }

  return (
    <li>
      <div className={classes.profileNameEmail}>
        <Tooltip title={name || email || ""}>
          <Typography variant="h6" className={classes.profileInfo}>
            {name || email}
          </Typography>
        </Tooltip>
        <Tooltip title={name ? email || "" : ""}>
          <Typography
            variant="caption2"
            className={cx(classes.profileInfo, classes.emailRoleStyle)}
          >
            {name ? email : null}
          </Typography>
        </Tooltip>
        <Typography
          variant="caption2"
          className={cx(classes.profileInfo, classes.emailRoleStyle)}
        >
          {renderRole(role)}
        </Typography>
      </div>
    </li>
  );
}
