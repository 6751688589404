import React from "react";
import { useTranslation } from "react-i18next";
import { AppError } from "@zeos/platform";

import { getErrorWrapperProps } from "../../data/predefined-errors";
import { ErrorWrapper } from "../ErrorWrapper/ErrorWrapper";

export const ErrorFactory = ({
  error
}: {
  /**
   * Error object
   */
  error: AppError;
}): React.ReactElement => {
  const { t } = useTranslation();

  return <ErrorWrapper {...getErrorWrapperProps(error, t)} />;
};
