import { Me, PortalApp, sentry } from "@zeos/platform";
import { enrichGA4Event } from "@zeos/platform";
import { GoogleAnalyticsConfig } from "core/portal-config";
import { sanitize } from "util/string";
import { gaEnabled, sentryConsentEnabled } from "../trackers/tracker-status";

const handler = {
  apply(target: any, thisArg: any, argumentsList: any) {
    // UserCentrics event is being sent GA4
    let userCentricsEvent = false;
    for (const e of argumentsList) {
      if (e.event === "consent_status") {
        window.ga4DataLayer.push(e);
        userCentricsEvent = true;
        continue;
      }

      if (
        typeof e.event === "string" &&
        e.event.search("EXPLICIT_DENY") !== -1
      ) {
        window.ga4DataLayer.push({
          event: "customGA4Event",
          event_name: "explicit_deny",
          label: "consent",
          value_text: e.event,
          component_context: "UserCentrics Event",
          page_context: "ZEOS one - shell"
        });
        userCentricsEvent = true;
        continue;
      }

      if (e.event === "customEvent") {
        window.ga4DataLayer.push({
          event: "customGA4Event",
          event_name: e.action,
          component_context: e.category ?? e.component_context,
          label: e.label,
          value_text: e.value_text,
          page_context: e.page_context ?? e.module_name
        });
        continue;
      }
    }

    if (userCentricsEvent) {
      return;
    }

    // other events should be captured on sentry because they will point out where we are calling the incorrect tracking function from SDK.
    const message =
      "Events added to dataLayer will not be automatically sent to Google Analytics 4. Please use ga4DataLayer instead to ensure proper tracking.";
    console.warn(`Warning: ${message}`);

    sentryConsentEnabled().then(enabled => {
      if (enabled) {
        sentry.captureError(
          new Error("Event Tracking Mismatch - Data will not be recorded", {
            cause: {
              code: "EventSwallowedError",
              reason: "dataLayer array is not attached to GTM.",
              message,
              timestamp: new Date().toISOString(),
              affectedComponents: ["DataLayer", "Analytics"],
              ignoredEvents: argumentsList
            }
          }),
          {
            category: "Analytics"
          }
        );
      }
    });

    return Reflect.apply(target, thisArg, argumentsList);
  }
};

/*
 * adds dataLayer as a prop to the window object
 */
declare global {
  interface Window {
    ga4DataLayer: any[];
    dataLayer: any[];
  }
}

window.ga4DataLayer = window.ga4DataLayer || [];
window.dataLayer = window.dataLayer || [];
window.dataLayer.push = new Proxy(Array.prototype.push, handler);

/**
 * this attaches the initial page view to the dataLayer.
 * This event is enriched and sent to GTM while the app is bootstrapping
 */
export function initialPageView(): void {
  window.ga4DataLayer.push({
    "gtm.start": new Date().getTime(),
    event: "gtm.js"
  });
}

/**
 * Checks Google Analytics configuration and active status, then
 * it constructs GTM (Google Tag Manager) javascript snippet and append it
 * to the document head
 * @param config - Portal's google analytics configuration
 */
export const appendGTMScript = (config: GoogleAnalyticsConfig): void => {
  const gtmKey = sanitize(config.GTM_Key);
  const gtmEnvironment = sanitize(config.GTM_Environment);
  const gtmScript = document.createElement("script");
  gtmScript.type = "text/javascript";
  gtmScript.text = `
      (function (d, s, l, i) {
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.id = 'gtm';
        j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl + '${gtmEnvironment}';
        f.parentNode.insertBefore(j, f);
      })(document, 'script', 'ga4DataLayer', '${gtmKey}');
    `;
  try {
    document.head.appendChild(gtmScript);
  } catch (error) {
    const message =
      "[GTM] failed to append Google Analytics javascript snippet to document head, Google Analytics data will not be collected, caused by:";
    console.error(message, error);
    sentryConsentEnabled().then(enabled => {
      if (enabled) {
        sentry.captureError(new Error(message, { cause: error }), {
          category: "Analytics"
        });
      }
    });
  }
};

/**
 * Start Google Analytics Tracking
 *
 * Enriches initial page view event with
 * custom dimensions and appends GA script
 *
 * @param config GA Config
 * @param me Me object
 * @param app Portal App Manifest
 */
export const startGTM = async (
  config: GoogleAnalyticsConfig,
  me: Me,
  app: PortalApp
): Promise<void> => {
  /*
   * updates the page view event with user info
   */
  window.ga4DataLayer = window.ga4DataLayer.map(d => {
    if (d.event === "gtm.js") {
      return {
        ...enrichGA4Event(d, me, app),
        user_id: me.principal_user_id,
        event: d.event
      };
    }
    return d;
  });

  const isGaEnabled = await gaEnabled(config);

  /*
   * now that the pageview is updated, we mount the GTM script
   */
  if (!document.querySelector("#gtm") && isGaEnabled) {
    appendGTMScript(config);
  }
};
