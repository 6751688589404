import React from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../core/store/hooks";
import {
  changeBreadcrumbs,
  resetBreadcrumbs
} from "../core/store/navigation/navigationSlice";
import BackIcon from "@zeos/icons/ArrowLeftIcon";
import { listenToUpdateBreadcrumbs } from "@zeos/platform";
import { Breadcrumbs } from "@zeos/components";
import { BreadcrumbsProps } from "@zeos/components/Breadcrumbs";
import { NavigationBarNavOptionItem } from "../core/app-config";
import { appMenuIconMapping } from "../util/app-icons";

interface CustomSingleSpaEvent extends Event {
  detail: {
    appsByNewStatus: {
      MOUNTED: string[];
    };
  };
}

export const useBreadcrumbs = ({
  currentNav,
  selectedNav
}: {
  currentNav?: NavigationBarNavOptionItem;
  selectedNav?: NavigationBarNavOptionItem;
}): BreadcrumbsProps["items"] => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();

  const { breadcrumbs: breadcrumbsMicrofrontendsState, showBackButton } =
    useAppSelector(state => state.navigation);

  React.useEffect(() => {
    const unsubscribe = listenToUpdateBreadcrumbs(payload => {
      dispatch(
        changeBreadcrumbs({
          ...payload,
          showBackButton: payload?.showBackButton ?? false
        })
      );
    });
    return unsubscribe;
  }, []);

  React.useEffect(() => {
    const listener = (event: CustomSingleSpaEvent) => {
      const newMountedApp = event.detail?.appsByNewStatus?.MOUNTED[0];
      if (newMountedApp) {
        dispatch(resetBreadcrumbs());
      }
    };

    window.addEventListener(
      "single-spa:before-routing-event",
      listener as EventListener
    );

    return () => {
      window.removeEventListener(
        "single-spa:before-routing-event",
        listener as EventListener
      );
    };
  }, []);

  const breadcrumbs = React.useMemo(() => {
    if (showBackButton) {
      return [
        {
          label:
            breadcrumbsMicrofrontendsState[0]?.label ?? "Back to previous view",
          icon: <BackIcon size={16} />,
          href: `${selectedNav?.link ?? ""}${breadcrumbsMicrofrontendsState[0]?.url ?? ""}`,
          dataTestId:
            breadcrumbsMicrofrontendsState[0]?.dataTestId ?? "breadcrumb-back"
        }
      ];
    }

    if (!currentNav) {
      if (Boolean(breadcrumbsMicrofrontendsState[0]?.label)) {
        return [
          ...breadcrumbsMicrofrontendsState.map(
            ({ label, url, dataTestId, icon }) => {
              const Icon = Boolean(icon)
                ? (appMenuIconMapping[
                  icon as keyof typeof appMenuIconMapping
                  ] as React.ComponentType<any>) // FIXME:Temporarily cast type to match NavigationBarNavOptionItem's icon type.
                : null;

              return {
                label,
                href: `${selectedNav?.link ?? ""}${url ?? ""}`,
                dataTestId: dataTestId ?? "breadcrumb-feature",
                icon: Icon ? <Icon size={20} /> : null
              };
            }
          )
        ];
      }
      return [];
    }

    const Icon = selectedNav?.isPrimary ? selectedNav.icon : currentNav.icon;

    const breadcrumbs: Parameters<typeof Breadcrumbs>[0]["items"] = [
      {
        label: t(selectedNav?.isPrimary ? selectedNav.label : currentNav.label),
        icon: Icon && <Icon size={20} />,
        href: selectedNav?.link ?? "",
        dataTestId: `breadcrumb-primary-${selectedNav?.id}`
      }
    ];

    if (
      selectedNav &&
      !selectedNav.isPrimary &&
      currentNav.children &&
      currentNav.children?.length > 1
    ) {
      breadcrumbs.push({
        label: t(selectedNav.label),
        href: selectedNav.link,
        dataTestId: `breadcrumb-secondary-${selectedNav?.id}`
      });
    }

    if (breadcrumbsMicrofrontendsState.length) {
      breadcrumbs.push(
        ...breadcrumbsMicrofrontendsState.map(({ label, url, dataTestId }) => ({
          label,
          href: `${selectedNav?.link ?? ""}${url ?? ""}`,
          dataTestId: dataTestId ?? "breadcrumb-feature"
        }))
      );
    }

    return breadcrumbs;
  }, [currentNav, selectedNav, breadcrumbsMicrofrontendsState]);

  return breadcrumbs;
};
