import { AppConfig } from "@zeos/platform";
import { ImportMap } from "typings";

export const appendImportMap = (apps: AppConfig[]) => {
  const imports = apps.reduce<ImportMap>(
    (accu, app) => ({
      ...accu,
      [app.name]: "http://localhost:3000/local-build/main.js?default"
    }),
    {}
  );

  const existingOverrideList = window?.importMapOverrides?.getOverrideMap(true);

  Object.entries(imports).forEach(([name, src]) => {
    const override = existingOverrideList?.imports?.[name];

    if (override) {
      return;
    }

    window?.importMapOverrides?.addOverride?.(name, src);
    if (!src || src.includes("?default")) {
      window?.importMapOverrides?.disableOverride?.(name);
    }
  });

  const tag = document.createElement("script");
  tag.type = "systemjs-importmap";
  tag.textContent = JSON.stringify({ imports });
  document.head.appendChild(tag);
};
