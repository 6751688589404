import React from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Link,
  NavDrawer,
  VerticalMenu,
  Breadcrumbs
} from "@zeos/components";
import { Me } from "@zeos/platform";
import { navigateTo } from "../util/routing";
import {
  NavigationBarAppOption,
  NavigationBarNavOptions
} from "../core/app-config";
import NavBarAccount from "./NavBarAccount";
import { AlertDialog } from "./AlertDialog";
import { SnackbarList } from "./SnackbarList";
import { TopBar } from "./TopBar";
import { useBreadcrumbs } from "../hooks/useBreadcrumbs";

interface Props {
  me: Me | null;
  navigation: NavigationBarNavOptions;
  apps: NavigationBarAppOption[];
}

const findCurrentPrimaryNav = (
  tabs: NavigationBarNavOptions["tabs"],
  activeRoute: string
) =>
  tabs.find(({ children }) =>
    children?.some(({ link }) => activeRoute.startsWith(link))
  );

const findCurrentSecondaryNav = (
  tab: NavigationBarNavOptions["tabs"][number] | undefined,
  activeRoute: string
) => tab?.children?.find(({ link }) => activeRoute.startsWith(link));

const NAVBAR_OPEN_WIDTH = 248;

const Dashboard = ({
  navigation,
  children,
  apps,
  me
}: React.PropsWithChildren<Props>): JSX.Element => {
  const { t } = useTranslation();
  const [activeRoute, setActiveRoute] = React.useState("");

  const currentNav = findCurrentPrimaryNav(navigation.tabs, activeRoute);
  const selectedNav = findCurrentSecondaryNav(currentNav, activeRoute);

  const breadcrumbs = useBreadcrumbs({ currentNav, selectedNav });

  const homeHref = React.useMemo(() => {
    const { tabs } = navigation;
    const tabLink = tabs?.[0]?.link;

    return tabLink ?? "/";
  }, [apps]);

  React.useEffect(() => {
    const handleRouteChange = () => {
      setActiveRoute(window.location.pathname);
    };

    handleRouteChange();
    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const menuItems = React.useMemo(() => {
    return navigation.tabs.reduce((menuItems, tab) => {
      const primaryChild = tab.children?.find(({ isPrimary }) => isPrimary);
      const secondaryChildren = tab.children?.filter(
        ({ isPrimary }) => !isPrimary
      );

      if (primaryChild) {
        menuItems.push({
          primary: {
            ...primaryChild,
            label: t(primaryChild.label),
            onClick: () => navigateTo(primaryChild.link)
          },
          secondary: primaryChild.children?.length
            ? primaryChild.children?.map(child => ({
                ...child,
                label: t(child.label),
                onClick: () => navigateTo(child.link)
              }))
            : undefined
        });
      }

      menuItems.push({
        primary: {
          ...tab,
          label: t(tab.label),
          onClick: () =>
            navigateTo(
              secondaryChildren?.length ? secondaryChildren[0].link : tab.link
            )
        },
        secondary: secondaryChildren?.length
          ? secondaryChildren?.map(child => ({
              ...child,
              label: t(child.label),
              onClick: () => navigateTo(child.link)
            }))
          : undefined
      });

      return menuItems;
    }, [] as Parameters<typeof VerticalMenu>[0]["menuItems"]);
  }, [navigation.tabs]);

  return (
    <Box display="flex" flexDirection="row">
      <NavDrawer openWidth={NAVBAR_OPEN_WIDTH} open>
        <Link
          sx={{
            marginBottom: ({ spacing }) => spacing(5),
            padding: "0"
          }}
          onClick={() => navigateTo(homeHref)}
          underline="none"
        >
          <img src={"/assets/zeos/zeos.svg"} alt="zeos" />
        </Link>
        <VerticalMenu menuItems={menuItems} selectedItemId={selectedNav?.id} />
      </NavDrawer>
      <AlertDialog />
      <SnackbarList />
      {/* TODO REMOVE MINWDITH ONCE RESPONSIVENESS IS IN PLACE */}
      <Box display="flex" flexDirection="column" flexGrow={1} minWidth={0}>
        <TopBar>
          <Breadcrumbs
            onClick={(
              link: string,
              event: React.MouseEvent<HTMLAnchorElement>
            ) => {
              event.preventDefault();
              navigateTo(link);
            }}
            items={breadcrumbs}
          />
          <NavBarAccount isAccountSelectAllowed={true} />
        </TopBar>
        <Box overflow="auto" maxWidth={`calc(100vw - ${NAVBAR_OPEN_WIDTH}px)`}>
          <Box flexGrow={1} overflow="auto" minWidth={1168}>
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
