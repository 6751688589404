import { PortalAppManifest } from "@zeos/platform";
import { ParcelAbortError, ParcelManifestError } from "./parcel-errors";

export async function fetchManifest(
  manifestUrl: string,
  signal?: AbortSignal
): Promise<PortalAppManifest> {
  if (signal?.aborted)
    throw new ParcelAbortError(
      "Failed before fetching manifest: Signal aborted!"
    );

  try {
    const res = await fetch(manifestUrl, { signal });

    if (!res.ok) {
      throw new Error(
        `Failed to fetch manifest: ${res.statusText} (HTTP ${res.status})`
      );
    }

    return (await res.json()) as PortalAppManifest;
  } catch (error) {
    if (error instanceof Error && error.name === "AbortError") {
      throw new ParcelAbortError(
        "Failed while fetching manifest: Signal aborted!"
      );
    }

    if (error instanceof ParcelAbortError) {
      throw error;
    }

    throw new ParcelManifestError((error as Error).message);
  }
}
