import React from "react";
import Splash from "@zeos/components/Splash";
import { ErrorNotFoundIllustration } from "@zeos/illustrations/ErrorNotFoundIllustration";

export type ErrorWrapperProps = {
  title: string;
  description: React.ReactNode | string;
  illustration: typeof ErrorNotFoundIllustration;
  size?: "small" | "medium";
  direction?: "horizontal" | "vertical";
  code?: string;
  cta?: {
    label: string;
    onClick: React.MouseEventHandler;
  };
};

const styles: Record<string, React.CSSProperties> = {
  root: {
    height: "calc(50vh - 70px)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: 24,
    marginLeft: 248
  }
};

export const ErrorWrapper = (props: ErrorWrapperProps): React.ReactElement => (
  <div style={styles.root}>
    <Splash {...props} />
  </div>
);
