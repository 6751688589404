import Formatter from "./Formatter";

const TRANSLATION_KEY_PREFIX = "FORMATTERS.DURATION.";
const AGO = "AGO";
const AHEAD = "AHEAD";

// creates a nested i18next translation for provided key and count in related uom
const translate = (
  keyPart: string,
  isInFuture: boolean,
  count?: number
): string =>
  `$t(${TRANSLATION_KEY_PREFIX}${keyPart}_${isInFuture ? AHEAD : AGO}${
    count === undefined ? "" : "," + JSON.stringify({ count })
  })`;

const SECOND = 1;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
// according to wikipedia: "The mean month-length in the Gregorian calendar is 30.436875 days"
// so using this number to calculate how many months/years passed/left a bit more accurately.
const MONTH = 30.436875 * DAY;
const YEAR = 12 * MONTH;

// extract minutes, hours, days etc ("absolutely" rounded up)
// from the provided value in seconds
const extract = (value: number, uom: number) =>
  Math.round(Math.abs(value) / uom);

const TRANSLATION_FUNCTION_BY_BREAKDOWN: Record<
  number,
  (value: number) => string
> = {
  44: v => translate(`FEW_SECONDS`, v > 0),
  89: v => translate("MINUTES", v > 0, 1),
  [44 * MINUTE]: v => translate("MINUTES", v > 0, extract(v, MINUTE)),
  [89 * MINUTE]: v => translate("HOURS", v > 0, 1),
  [21 * HOUR]: v => translate("HOURS", v > 0, extract(v, HOUR)),
  [35 * HOUR]: v => translate("DAYS", v > 0, 1),
  [25 * DAY]: v => translate("DAYS", v > 0, extract(v, DAY)),
  [45 * DAY]: v => translate("MONTHS", v > 0, 1),
  [10 * MONTH]: v => translate("MONTHS", v > 0, extract(v, MONTH)),
  [17 * MONTH]: v => translate("YEARS", v > 0, 1),
  [Number.MAX_VALUE]: v => translate("YEARS", v > 0, extract(v, YEAR))
};

// no formatter options for now so providing `unknown` for the options param
export default class DurationFormatter implements Formatter<number, unknown> {
  /**
   * For the specified value in seconds returns amount of time passed (if value is negative)
   * or left (if value is positive) according to the breakdown used by dayjs
   * (https://day.js.org/docs/en/display/from-now#list-of-breakdown-range)
   * @param {number} value duration in seconds
   */
  public format(value: number): string {
    const translate = Object.entries(TRANSLATION_FUNCTION_BY_BREAKDOWN).find(
      ([breakDown]) => Math.abs(value) <= Number(breakDown)
    )![1];

    return translate(value);
  }
}
