import { getPortalConfig } from "core/portal-config";
import { NavigationBarAppOption } from "../core/app-config";

export const getAllowedAppOptions = (): NavigationBarAppOption[] => {
  const { apps } = getPortalConfig();

  return apps.map(
    app =>
      ({
        id: app.name,
        type: app.type,
        label: app.label,
        description: app.description,
        path: app.pathPrefix,
        icon: app.icon
      } as NavigationBarAppOption)
  );
};
