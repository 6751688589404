import React from "react";
import { SnackbarOptions } from "@zeos/platform";

import Snackbar from "@zeos/components/Snackbar";
import Link from "@zeos/components/Link";
import { useStyles } from "./SnackbarList.styles";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { getSnackbarList } from "core/store/selectors";
import { snackbarActions } from "core/store/snackbar/snackbar.reducer";

const horizontalPositions = ["left", "right"] as const;

export interface SnackbarItem extends SnackbarOptions {
  id: string;
}

export const SnackbarList = (): React.ReactElement => {
  const { classes, cx } = useStyles();
  const list = useAppSelector(getSnackbarList);
  const dispatch = useAppDispatch();
  const handleClose =
    (id: string, callback: () => void) => (event: any, reason: string) => {
      if (reason === "clickaway") {
        return;
      }

      if (callback && typeof callback === "function") {
        callback();
      }

      dispatch({ type: snackbarActions.SNACKBAR_REMOVE, id });
    };

  const renderSnackbar = (item: SnackbarItem) => {
    const handleCloseItem = handleClose(item.id, item.onClose ?? (() => {}));

    return (
      <Snackbar
        key={item.id}
        anchorOrigin={item.anchorOrigin}
        classes={{
          root: classes.snackBar
        }}
        open
        autoHideDuration={item.autoHideDuration}
        onClose={handleCloseItem}
        cta={
          item &&
          item.cta && (
            <Link
              variant="h5"
              darkMode={!item.status || item.status === "default"}
              /* this fun casting is brought to you by the fact that SDK does not have React in it */
              onClick={
                item.cta.onClick as unknown as
                  | React.MouseEventHandler<HTMLAnchorElement>
                  | undefined
              }
            >
              {item.cta.label}
            </Link>
          )
        }
        headline={item.headline}
        message={item.message}
        title={item.headline}
        type={item.status}
      />
    );
  };

  return (
    <>
      {horizontalPositions.map(position => (
        <div
          className={cx(
            classes.snackBarList,
            classes[
              `${position}SnackBarList` as
                | "leftSnackBarList"
                | "rightSnackBarList"
            ]
          )}
          key={position}
        >
          {list
            .filter(item => item.position === position)
            .slice(0, 5)
            .map(renderSnackbar)}
        </div>
      ))}
    </>
  );
};
