import { initialPageView } from "core/tracking";

window.addEventListener("ucConsentEventV2", (e: any) => {
  if (
    ["onUpdateServices", "onAcceptAllServices", "onDenyAllServices"].includes(
      e.detail.action
    )
  ) {
    window.location.reload();
  }
});

initialPageView();
