import { useCallback, useEffect, useReducer } from "react";
import { fetch, sentry, snackbar } from "@zeos/platform";
import { useTranslation } from "react-i18next";
import { RefreshToken, Tokens } from "@okta/okta-auth-js";

interface AvailableAccount {
  id: string;
  name: string;
  role: string;
  enabled: boolean;
  type: string;
}

const LIMIT = 10;

type State = {
  accountId: string;
  fetchingStatus: "NOT_STARTED" | "IN_PROGRESS" | "ERROR" | "SUCCESS";
  availableAccounts: AvailableAccount[];
  areMoreAccountsAvailable: boolean;
  accountsFetchOffset: number;
  searchTerm: string;
  error: Error | null;
};

type Action =
  | {
      type: "RESET";
    }
  | {
      type: "SET_ACCOUNT_ID";
      accountId: string;
    }
  | {
      type: "AVAILABLE_ACCOUNTS_START";
    }
  | {
      type: "AVAILABLE_ACCOUNTS_ERROR";
      error: Error;
    }
  | {
      type: "AVAILABLE_ACCOUNTS_SUCCESS";
      availableAccounts: AvailableAccount[];
      areMoreAccountsAvailable: boolean;
      accountsFetchOffset: number;
    }
  | {
      type: "SET_SEARCH_TERM";
      searchTerm: string;
    };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case "RESET":
      return {
        accountId: "",
        fetchingStatus: "NOT_STARTED",
        availableAccounts: [],
        areMoreAccountsAvailable: false,
        accountsFetchOffset: 0,
        searchTerm: "",
        error: null
      };
    case "SET_ACCOUNT_ID":
      return {
        ...state,
        accountId: action.accountId
      };
    case "AVAILABLE_ACCOUNTS_START":
      return {
        ...state,
        fetchingStatus: "IN_PROGRESS",
        error: null
      };
    case "AVAILABLE_ACCOUNTS_ERROR":
      return {
        ...state,
        fetchingStatus: "ERROR",
        error: action.error
      };
    case "AVAILABLE_ACCOUNTS_SUCCESS": {
      return {
        ...state,
        fetchingStatus: "SUCCESS",
        error: null,
        availableAccounts: [
          ...state.availableAccounts,
          ...action.availableAccounts
        ],
        areMoreAccountsAvailable: action.areMoreAccountsAvailable,
        accountsFetchOffset: action.accountsFetchOffset
      };
    }
    case "SET_SEARCH_TERM": {
      return {
        ...state,
        fetchingStatus: "NOT_STARTED",
        availableAccounts: [],
        areMoreAccountsAvailable: false,
        accountsFetchOffset: 0,
        searchTerm: action.searchTerm
      };
    }
    default:
      return state;
  }
}

let controller: AbortController | undefined;

export type AvailableAccounts = ReturnType<typeof useAvailableAccounts>;
export function useAvailableAccounts(principal_user_id: string) {
  const { t } = useTranslation();
  const [state, dispatch] = useReducer<typeof reducer, Action>(
    reducer,
    {
      type: "RESET"
    },
    () => ({
      accountId: "",
      fetchingStatus: "NOT_STARTED",
      availableAccounts: [],
      areMoreAccountsAvailable: false,
      accountsFetchOffset: 0,
      searchTerm: "",
      error: null
    })
  );

  useEffect(() => {
    controller = new AbortController();
    dispatch({
      type: "SET_ACCOUNT_ID",
      accountId: principal_user_id
    });

    return () => {
      if (controller) {
        controller.abort();
      }
    };
  }, [principal_user_id]);

  const fetchAvailableAccounts = () => {
    if (
      !["NOT_STARTED", "ERROR"].includes(state.fetchingStatus) &&
      !state.areMoreAccountsAvailable
    ) {
      return;
    }

    let signal: AbortSignal | undefined;
    if (state.fetchingStatus === "IN_PROGRESS" && controller) {
      controller.abort();
    }
    if (!controller && window.AbortController) {
      controller = new AbortController();
    }
    if (!signal && controller) {
      signal = controller.signal;
    }

    const queryParams = [
      { key: "limit", value: LIMIT },
      { key: "offset", value: state.accountsFetchOffset },
      { key: "search", value: state.searchTerm }
    ]
      .filter(param => !!param.value)
      .map(
        ({ key, value }) =>
          `${key}=${encodeURIComponent(value as string | number)}`
      )
      .join("&");

    dispatch({ type: "AVAILABLE_ACCOUNTS_START" });

    fetch(`/api/iam/users/${principal_user_id}/accounts?${queryParams}`, {
      signal
    })
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Fetching available Accounts failed`);
        }
        return res.json();
      })
      .then(({ accounts }: { accounts: AvailableAccount[] }) => {
        dispatch({
          type: "AVAILABLE_ACCOUNTS_SUCCESS",
          areMoreAccountsAvailable: accounts.length === LIMIT,
          accountsFetchOffset: state.accountsFetchOffset + accounts.length,
          availableAccounts: accounts
        });
      })
      .catch(error => {
        if (error.name === "AbortError") {
          console.error("Request aborted");
        } else {
          sentry.captureError(error);
          dispatch({
            type: "AVAILABLE_ACCOUNTS_ERROR",
            error
          });
        }
      })
      .finally(() => {
        controller = undefined;
      });
  };

  useEffect(() => {
    fetchAvailableAccounts();
  }, [state.searchTerm]);

  const setActiveAccount = useCallback(
    (accountId: string) => {
      const currentAccountId = state.accountId;
      if (accountId === currentAccountId) {
        return;
      }
      const accountRecord = state.availableAccounts.find(
        ({ id }) => id === accountId
      );
      if (!accountRecord) {
        return;
      }
      window.ga4DataLayer.push({
        event: "customGA4Event",
        event_name: "Click",
        label: "Sign into other account",
        value_text: accountRecord.name,
        component_context: "Account Switch",
        page_context: "ZEOS One shell - account selector"
      });

      dispatch({ type: "SET_ACCOUNT_ID", accountId });

      // We need to update the extraParams of the refreshToken manually
      // Step is required as okta SDK doesnt override the extraParams if the value is provided
      // But rather keeps sending the old one
      const currentTokens = window.okta.tokenManager.getTokensSync();
      const extraParams = { account_id: accountId };
      const refreshToken = {
        ...currentTokens.refreshToken,
        extraParams: {
          ...(currentTokens.refreshToken?.extraParams ?? {}),
          ...extraParams
        }
      } as RefreshToken;

      window.okta.token
        .renewTokensWithRefresh(
          {
            responseType: ["id_token", "refresh_token", "token"],
            extraParams
          },
          refreshToken
        )
        .then(function (tokens: Tokens) {
          window.okta.tokenManager.setTokens(tokens);
          window.location.reload();
        })
        .catch(e => {
          // Show the error in the console
          console.error(`Got error while switching account: ${e.message}`);
          sentry.captureError(e);

          snackbar.showMessage({
            message: t(
              "NAVIGATION_BAR.USER_PROFILE.ACCOUNT_SWITCH.ERROR.GENERAL_DESCRIPTION"
            ),
            status: "error",
            headline: t(
              "NAVIGATION_BAR.USER_PROFILE.ACCOUNT_SWITCH.ERROR.TITLE"
            )
          });

          // if account switch fails we reset selector state
          // And enable it
          dispatch({ type: "RESET" });

          // Restore selected account
          dispatch({ type: "SET_ACCOUNT_ID", accountId: currentAccountId });
        });
    },
    [state, dispatch]
  );

  return {
    areMoreAccountsAvailable: state.areMoreAccountsAvailable,
    isPending: state.fetchingStatus === "IN_PROGRESS",
    error: state.error,
    data: state.availableAccounts,
    loadMore: fetchAvailableAccounts,
    setActiveAccount,
    search: (searchTerm: string) => {
      if (searchTerm !== state.searchTerm) {
        dispatch({ type: "SET_SEARCH_TERM", searchTerm });
      }
    }
  };
}
