import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ALERT_DIALOG_CLOSE,
  ALERT_DIALOG_SHOW,
  AlertDialogData
} from "@zeos/platform";

export interface AlertDialogState {
  isOpen: boolean;
  alertDialogConfig: AlertDialogData;
}

export const initialState: AlertDialogState = {
  isOpen: false,
  alertDialogConfig: {
    title: "",
    description: "",
    onSubmit: (): void => {}
  }
};

const alertDialogSlice = createSlice({
  name: "alertDialog",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addMatcher(
      action => action.type === ALERT_DIALOG_SHOW,
      (state, action: PayloadAction<AlertDialogData>) => {
        state.isOpen = true;
        state.alertDialogConfig = action.payload;
      }
    );
    builder.addMatcher(
      action => action.type === ALERT_DIALOG_CLOSE,
      state => {
        state.isOpen = false;
      }
    );
  }
});

export default alertDialogSlice.reducer;
