import React from "react";

import { DropdownHeader } from "./DropdownHeader";
import { DropdownMenuOptions } from "./DropdownMenuOptions";
import { DropdownFooter } from "./DropdownFooter";
import { UserProfileData } from "../types";

export interface DropdownProps {
  userProfile: UserProfileData["userProfile"];
  defaultMenuOptions: UserProfileData["dropdownOptions"];
  footerOptions: UserProfileData["footerOptions"];
  handleClose: () => void;
}

type Props = DropdownProps;

function Dropdown({
  userProfile,
  defaultMenuOptions,
  footerOptions,
  handleClose
}: Props): React.ReactElement {
  return (
    <>
      <DropdownHeader userProfile={userProfile} />

      <DropdownMenuOptions
        dropdownOptions={defaultMenuOptions}
        handleClose={handleClose}
      />

      <DropdownFooter footerOptions={footerOptions} handleClose={handleClose} />
    </>
  );
}

export default Dropdown;
