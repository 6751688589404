import { Store } from "redux";
import { PhaseHook } from "core/hooks-decorator";
import { AppStatusAction } from "./app-status/app-status.actions";

export const reduxHook = (
  store: Store,
  startAction: AppStatusAction,
  successAction: AppStatusAction,
  errorAction: (error: Error) => AppStatusAction
): PhaseHook => {
  const start = async () => {
    store.dispatch(startAction);
  };

  const success = async () => {
    store.dispatch(successAction);
  };

  const error = async (
    _phase: string,
    _appName: string,
    _phaseProps: any,
    e: Error
  ) => {
    store.dispatch(errorAction(e));
  };

  return { start, success, error };
};
