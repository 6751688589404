export const APP_LOAD_START = "APP_LOAD_START";
export const APP_LOAD_ERROR = "APP_LOAD_ERROR";
export const APP_BOOTSTRAP_START = "APP_BOOTSTRAP_START";
export const APP_BOOTSTRAP_FINISH = "APP_BOOTSTRAP_FINISH";
export const APP_MOUNT_START = "APP_MOUNT_START";
export const APP_MOUNT_FINISH = "APP_MOUNT_FINISH";
export const APP_UNMOUNT_START = "APP_UNMOUNT_START";
export const APP_UNMOUNT_FINISH = "APP_UNMOUNT_FINISH";

export type AppStatusPayload = {
  appName: string;
  appLabel: string;
  error?: Error;
};

export type AppStatusAction = {
  /**
   * Action type
   */
  type: string;

  /**
   * Action Payload
   */
  payload: AppStatusPayload;
}

export const appLoadingStarted = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_LOAD_START,
  payload: {
    appName,
    appLabel
  }
});

export const appLoadingFailed = (
  appName: string,
  appLabel: string,
  error: Error
): AppStatusAction => ({
  type: APP_LOAD_ERROR,
  payload: {
    appName,
    appLabel,
    error
  }
});

export const appBootstrappingStarted = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_BOOTSTRAP_START,
  payload: {
    appName,
    appLabel
  }
});

export const appBootstrappingFinished = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_BOOTSTRAP_FINISH,
  payload: {
    appName,
    appLabel
  }
});

export const appMountingStarted = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_MOUNT_START,
  payload: {
    appName,
    appLabel
  }
});

export const appMountingFinished = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_MOUNT_FINISH,
  payload: {
    appName,
    appLabel
  }
});

export const appUnmountingStarted = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_UNMOUNT_START,
  payload: {
    appName,
    appLabel
  }
});

export const appUnmountingFinished = (
  appName: string,
  appLabel: string
): AppStatusAction => ({
  type: APP_UNMOUNT_FINISH,
  payload: {
    appName,
    appLabel
  }
});
