import {
  GoogleAnalyticsConfig,
  SentryConfig,
  AppcuesConfig,
  HotjarConfig,
  MonitoringConfig
} from "../portal-config";
import {
  SERVICES_CONSENT_NAME_MAP,
  ServicesName,
  getServiceConsent
} from "./consent";

/**
 * ----
 * File which contains the status of a tracker i.e. if the given
 * tracker is active or inactive.
 *
 * Tracker status is based on:-
 * 1. tracker's own config
 * 2. user's consent status for the given tracker
 * ----
 */

/**
 * Returns a promise which always resolves a boolean value which it will
 * be true only if the service is enabled in portal config and the service
 * consent is given by the user
 *
 * @param serviceId ZDirectServiceId
 * @param isServiceActive boolean
 */
const getServiceStatusPromise = (
  serviceId: ServicesName,
  isServiceActive: boolean
): Promise<boolean> =>
  !isServiceActive ? Promise.resolve(false) : getServiceConsent(serviceId);

/**
 * Returns a boolean value indicates if GTM is activated in
 * portal config and the service consent is given by the user
 *
 * @param config SentryConfig
 */
export function gaEnabled(
  googleAnalytics: GoogleAnalyticsConfig
): Promise<boolean> {
  return getServiceStatusPromise(
    SERVICES_CONSENT_NAME_MAP.GTM,
    googleAnalytics.active
  );
}

/**
 * Returns a boolean value indicating appcues being enabled
 * in portal config and the consent given by user
 *
 * @param config AppcuesConfig
 */
export function appcuesEnabled(config: AppcuesConfig): Promise<boolean> {
  return getServiceStatusPromise(
    SERVICES_CONSENT_NAME_MAP.APPCUES,
    Boolean(config) && config.active && Boolean(config.accountId)
  );
}

/**
 * Returns a boolean value indicating hotjar being enabled
 * in portal config and the consent given by user
 *
 * @param config HotjarConfig
 */
export function hotjarEnabled(config: HotjarConfig): Promise<boolean> {
  return getServiceStatusPromise(
    SERVICES_CONSENT_NAME_MAP.HOTJAR,
    Boolean(config) && config.active && Boolean(config.siteID)
  );
}

/**
 * Returns Tracing is enabled or not based on the config file
 * @param config
 */
export function tracingConfigEnabled(config: MonitoringConfig): boolean {
  const tracingConfigStatus =
    config && config.tracing && config.tracing.enabled;
  return Boolean(tracingConfigStatus);
}

/**
 * Returns tracing is enabled or not based on consent.
 */
export function tracingConsentEnabled(): Promise<boolean> {
  return getServiceStatusPromise(SERVICES_CONSENT_NAME_MAP.TRACING, true);
}

/**
 * Returns a boolean value indicates if sentry is activated based on
 * portal config
 *
 * @param config SentryConfig
 */
export function sentryConfigEnabled(config: SentryConfig): boolean {
  return config && config.active;
}

/**
 * Returns a promise to indicate tracing is enabled
 * or not based on user consent.
 */
export function sentryConsentEnabled(): Promise<boolean> {
  return getServiceStatusPromise(SERVICES_CONSENT_NAME_MAP.SENTRY, true);
}
