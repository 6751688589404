import type {
  PortalConfig,
  Severity,
  SentryConfig,
  GoogleAnalyticsConfig,
  HotjarConfig,
  NavigationConfig,
  NavigationItemConfig,
  MonitoringConfig
} from "@zeos/platform";
import { AppError, AppErrorType } from "@zeos/platform";

type AppcuesConfig = PortalConfig["appcues"];
type I18NextConfig = PortalConfig["i18next"];

export type {
  PortalConfig,
  Severity,
  I18NextConfig,
  SentryConfig,
  GoogleAnalyticsConfig,
  HotjarConfig,
  AppcuesConfig,
  NavigationConfig,
  NavigationItemConfig,
  MonitoringConfig
};

export { getPortalConfig } from "@zeos/platform";

/**
 * Returns the portal load status
 *
 * Load status here means the status of the portal when it was first loaded
 */
export function getPortalLoadStatus(): number | undefined {
  return window.LoadedStatus;
}

export function getPortalLoadError(): AppError {
  const { LoadedStatus } = window;
  switch (LoadedStatus) {
    case 404:
      // When message is empty, default message for
      // not found error will be used
      return new AppError("", { type: "NotFound" });
    case 403:
    case 401:
      // When message is empty, default message for
      // forbidden error will be used
      return new AppError("", { type: "Forbidden" });
    default:
      return new AppError(`${LoadedStatus}`, {});
  }
}

/**
 * Returns the error type if exists, undefined otherwise
 *
 * @returns AppErrorType
 */
export function getAuthErrorType(): AppErrorType {
  return window.authErrorType;
}

/**
 * Returns the portal load path.
 *
 * The portal load path is the path using which portal is loaded.
 * This path may not represent an app in case of an error.
 */
export function getLoadPath(): string | undefined {
  return window.LoadedPath;
}
