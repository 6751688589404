import { fetch, MonitoringConfig, sentry, AppError } from "@zeos/platform";
import type { Me } from "@zeos/platform";

const ME_ENDPOINT_TIMEOUT = 30000;

async function handleError<T>(res: Response, apiName: string): Promise<T> {
  if (res.ok) {
    return res.json() as Promise<T>;
  }

  const errorResponse = await res.json();
  throw new AppError(`Got ${res.status} for "${apiName}" api`, {
    type: "Generic",
    code: res.statusText,
    title: errorResponse.detail || errorResponse.title
  });
}

/**
 * Api to close lightstep spans through the backend.
 *
 * @param tracing
 */
export const closeSpan = (
  tracing: MonitoringConfig["tracing"],
  baggage: Record<string, unknown>,
  spanEndTime: number
): Promise<void> => {
  const body = {
    portalSpan: tracing.portalSpan,
    rootSpan: tracing.rootSpan,
    serverTimeOrigin: tracing.serverTimeOrigin,
    spanEndTime,
    baggage
  };

  return fetch("/close-span", {
    method: "POST",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json"
    }
  })
    .then(res => res.json())
    .catch(err => {
      sentry.captureError(err);
    });
};

export const fetchMe = (): Promise<Me> => {
  return fetch("/api/auth/me", { timeout: ME_ENDPOINT_TIMEOUT })
    .then(res => handleError<Me>(res, "me"))
    .then(me => {
      /**
       * Keycloak response doesn't contain this field,
       * so we cast it to a boolean false value
       *
       * TODO: remove this when we get rid of Keycloak as auth provider
       *  */
      me.has_multiple_accounts = !!me.has_multiple_accounts;

      return me;
    })
    .catch(e => {
      if ("code" in e) {
        throw e;
      } else {
        // Re-throw any error to get more informative error in sentry
        // with this error we would just get "failed to fetch"
        throw new Error(`Got error while "me" call: ${e.message}`);
      }
    });
};

export const fetchMerchants = (bpids: string[]): Promise<any[]> => {
  const merchants = (bpids || []).map(bpid =>
    fetch(`/api/merchants/${bpid}`, {
      headers: {
        Accept: "application/json"
      }
    }).then(res => res.json())
  );

  return Promise.all(merchants).then(merchants =>
    merchants
      .filter(merchant => {
        if (merchant.errors && merchant.errors.length > 0) {
          console.warn(
            "errors detected while fetching merchants",
            merchant.errors[0]
          );
          return false;
        }
        return true;
      })
      .sort((m1, m2) => {
        if (m1.name < m2.name) return -1;
        if (m1.name > m2.name) return 1;
        return 0;
      })
  );
};
