import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";
import { getClientSpan } from "../clientSpan";
import { loadEventFallback } from "../loadEventFallback";
import { getTracer } from "../tracer";
import { hasPerformanceApi } from "../utils";

import { determineConnectionType } from "./connection";
import { determineEntrySpanName, getSortedPerformanceEntries } from "./entries";
import { startPerformanceObservers } from "./observers";
import { ResourceEntry } from "./types";

export const traceEntries = (
  tracer: Tracer,
  parent: SpanImp,
  entries: Array<ResourceEntry>
): SpanImp => {
  const loadResourcesStartTime = Math.min(...entries.map(a => a.startTime));
  const loadResourcesEndTime = Math.max(...entries.map(a => a.endTime));

  const resourcesSpan = tracer.startSpan("load_resources", {
    childOf: parent,
    startTime: loadResourcesStartTime
  }) as SpanImp;
  resourcesSpan.setTag("collected_from", "performance_api");

  entries.forEach(({ startTime, endTime, ...entry }) => {
    const span = tracer.startSpan(determineEntrySpanName(entry), {
      childOf: resourcesSpan,
      startTime
    }) as SpanImp;

    span.addTags(entry);
    span.finish(endTime);
  });

  resourcesSpan.finish(loadResourcesEndTime);
  return resourcesSpan;
};

/**
 * Traces performance entries based on types of resources and mark
 *
 * Creates the resource spans based on types of resources and mark
 * and sets the connection type to the passed parent span
 *
 * @param tracer Lightstep Tracer
 * @param parent Lightstep Span
 * @param initatorTypes Types of resources to trace
 * @param mark Trace resources after a specific mark
 *
 * @return Load Resources Span
 */
export const tracePerformanceEntries = (
  tracer: Tracer,
  parent: SpanImp,
  initiatorTypes = ["link", "script"],
  mark = ""
): SpanImp | undefined => {
  if (!hasPerformanceApi()) return undefined;

  const entries = getSortedPerformanceEntries(initiatorTypes, mark);
  const resourcesSpan = traceEntries(tracer, parent, entries);

  const connectionType = determineConnectionType(entries);
  parent.setTag("connection_type", connectionType);

  return resourcesSpan;
};

/**
 * Traces performance entries and adds entrypoint span
 * if performance API is enebled. Otherwise, it falls back to
 * load event tracing.
 *
 * Load event tracing does not provide any details on what resources are loaded.
 */
export const startPerformanceTracing = (): void => {
  const tracer = getTracer("web_portal");
  const clientSpan = getClientSpan(tracer);
  try {
    if (hasPerformanceApi()) {
      startPerformanceObservers(clientSpan);
      const resourcesSpan = tracePerformanceEntries(tracer, clientSpan, [
        "script",
        "link",
        "fetch"
      ])!;
      clientSpan.setBeginMicros(resourcesSpan.beginMicros());

      // entrypoint span calculates the time between loading the resources and bootstraping the portal
      const entrySpan = tracer.startSpan("bootstrap_portal", {
        childOf: clientSpan,
        startTime: resourcesSpan.endMicros()
      }) as SpanImp;
      entrySpan.finish();
    } else {
      // We throw error and call loadEventFallback in catch block
      throw new Error();
    }
  } catch (e) {
    // This function can never throw an error because
    // firstly, it creates an event listener and secondly
    // it is not dependent on experimental browser APIs
    loadEventFallback(tracer, clientSpan);
  }
};
