import Formatter from "./Formatter";
import { formatCompact } from "./utils";

export enum DisplayEnum {
  CODE = "code",
  SYMBOL = "symbol"
}

export interface CurrencyFormatterOptions {
  compact: boolean;
  showCents: boolean;
  currency?: string;
  display?: DisplayEnum;
}

const displayToCurrencyDisplayMap: { [key in DisplayEnum]: Intl.NumberFormatOptions['currencyDisplay'] } = {
  code: "code",
  symbol: "narrowSymbol"
};

export default class CurrencyFormatter
  implements Formatter<number, CurrencyFormatterOptions>
{
  private localization = "en-GB";
  private defaultCurrency = "EUR";
  private defaultDisplay = DisplayEnum.SYMBOL;

  /**
   * Returns joint and changed version of NumberFormatParts: currency, fraction, etc.
   * @param  {Intl.NumberFormatPart[]} parts
   * @returns string
   */
  private prepareFormat(parts: Intl.NumberFormatPart[]): string {
    return parts
      .map(part => {
        if (part.type === "currency") {
          return part.value + " ";
        }

        return part.value.trim().toLowerCase();
      })
      .reduce((acc, part) => acc + part);
  }

  /**
   * Returns precision depends of the value and compact option
   * showCents option rounds value so 12.34 -> 12; 12.56 -> 13
   * showCents does not affect compact option and true by default
   *
   * @param  {number} value
   * @param  {CurrencyFormatterOptions} options
   * @returns number
   */
  private getPrecision(
    value: number,
    options: CurrencyFormatterOptions
  ): number {
    const { compact, showCents = true } = options;

    if (compact) {
      return value < 1000 ? 0 : 1;
    }

    if (showCents) {
      return 2;
    }

    return 0;
  }

  /**
   * Returns humanized version of the currency
   * in case if compact is true it uses workaround until
   * notation is supported everywhere (it does not support by Safari)
   * for this purpose we use our own implementation formatNumberCompact
   * @param  {number} value
   * @param  {CurrencyFormatterOptions} options
   * @returns string
   */
  format(value: number, options: CurrencyFormatterOptions): string {
    try {
      const currency = options.currency || this.defaultCurrency;
      const currencyDisplay = options.display
        ? displayToCurrencyDisplayMap[options.display]
        : displayToCurrencyDisplayMap[this.defaultDisplay];

      const precision = this.getPrecision(value, options);
      const numberFormatInstance = new Intl.NumberFormat(this.localization, {
        style: "currency",
        currencyDisplay,
        currency,
        maximumFractionDigits: precision,
        minimumFractionDigits: precision
        // notation: rounded ? 'compact' : 'standard',
      });
      const formatToParts = numberFormatInstance.formatToParts(value);

      if (options.compact) {
        const compactNumber = formatCompact(value, {
          fractionalDigitals: precision
        });
        const currencyPart = formatToParts.find(
          ({ type }) => type === "currency"
        );
        return currencyPart?.value + " " + compactNumber;
      }

      return this.prepareFormat(formatToParts);
    } catch (err) {
      return (err as Error).message;
    }
  }
}
