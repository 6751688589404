import { Span, Tracer } from "opentracing";
import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { PhaseHook } from "core/hooks-decorator";
import {
  setFirstLoadedApp,
  clearAppSpan,
  getAppSpan,
  getRootSpan
} from "core/monitoring/tracing";

export const tracingHook = (
  tracer: Tracer,
  collectMetricsSuccess: ("phase" | "app" | "server")[] = [],
  collectMetricsError: ("phase" | "app" | "server")[] = []
): PhaseHook => {
  let appSpan: SpanImp;
  let phaseSpan: Span;

  /**
   * start tracing hook by creating the start phase span as a child to the app span
   * and add {first_load} tag and dispatch the tracing context change
   *
   * @param phase Phase name
   * @param _appName App name
   * @param phaseProps Hook props
   */
  const start = async (phase: string, _appName: string, phaseProps: any) => {
    appSpan = getAppSpan(tracer);
    phaseSpan = tracer.startSpan(phase, { childOf: appSpan });

    /**
     * Checks if {first_load} tag is undefined, this means that the module is already loaded
     * and {first_load} tag is already set to true.
     * else, {first_load} tag will be added to the app span with false value
     */
    if (appSpan.getTags().first_load === undefined) {
      appSpan.setTag("first_load", false);
    }

    // overwrite {parentSpan} of tracing context for SDK with the current phase span
    if (phaseProps && phaseProps.tracingContext) {
      // eslint-disable-next-line no-param-reassign
      phaseProps.tracingContext.parentSpan = phaseSpan;
    }
  };

  /**
   * Closes all the spans after the hook is executed successfully by closing all
   * the span corresponding to the hook metrics success
   *
   * @param _phase Phase name
   * @param _appName App name
   * @param phaseProps Hook props
   */
  const success = async (_phase: string, _appName: string, phaseProps: any) => {
    if (collectMetricsSuccess.includes("phase")) {
      phaseSpan.finish();

      // reset {parentSpan} of tracing context for SDK after success to undefined
      if (phaseProps && phaseProps.tracingContext) {
        // eslint-disable-next-line no-param-reassign
        phaseProps.tracingContext.parentSpan = undefined;
      }
    }

    if (collectMetricsSuccess.includes("app")) {
      appSpan.finish();
      clearAppSpan();
      setFirstLoadedApp();
    }

    if (collectMetricsSuccess.includes("server")) {
      getRootSpan(tracer).finish();
    }
  };

  /**
   * Closes all the spans after there is an error occurred in the hook by tagging
   * the spans with error and finishing these spans corresponding to the hook metrics error
   *
   * @param _phase Phase name
   * @param _appName App name
   * @param phaseProps Hook props
   */
  const error = async (_phase: string, _appName: string, phaseProps: any) => {
    if (collectMetricsError.includes("phase")) {
      phaseSpan.setTag("error", true);
      phaseSpan.log({ error: "Error occurred" });
      phaseSpan.finish();

      // reset {parentSpan} of tracing context for SDK after error occurred to undefined
      if (phaseProps && phaseProps.tracingContext) {
        // eslint-disable-next-line no-param-reassign
        phaseProps.tracingContext.parentSpan = undefined;
      }
    }

    if (collectMetricsError.includes("app")) {
      appSpan.setTag("error", true);
      appSpan.finish();
      clearAppSpan();
      setFirstLoadedApp();
    }

    if (collectMetricsError.includes("server")) {
      getRootSpan(tracer).setTag("error", true);
      getRootSpan(tracer).finish();
    }
  };

  return { start, success, error };
};
