import {
  APP_LOAD_START,
  APP_LOAD_ERROR,
  APP_BOOTSTRAP_START,
  APP_MOUNT_START,
  APP_MOUNT_FINISH,
  AppStatusPayload
} from "./app-status.actions";
import { Action, createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AppStatusState {
  loading: boolean;
  error?: Error;
  appName: string;
  appLabel: string;
}

export const initialState: AppStatusState = {
  loading: false,
  error: undefined,
  appName: "",
  appLabel: ""
};

const appStatusSlice = createSlice({
  name: "appStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action: Action) => action.type === APP_LOAD_ERROR,
      (state, action: PayloadAction<AppStatusPayload>) => {
        state.loading = false;
        state.error = action.payload.error;
        state.appName = action.payload.appName;
        state.appLabel = action.payload.appLabel;
      }
    );
    builder.addMatcher(
      (action: Action) => action.type === APP_MOUNT_FINISH,
      (state, action: PayloadAction<AppStatusPayload>) => {
        state.loading = false;
        state.error = undefined;
        state.appName = action.payload.appName;
        state.appLabel = action.payload.appLabel;
      }
    );
    builder.addMatcher(
      (action: Action) => [APP_LOAD_START, APP_BOOTSTRAP_START, APP_MOUNT_START].includes(action.type),
      (state, action: PayloadAction<AppStatusPayload>) => {
        state.loading = true;
        state.error = undefined;
        state.appName = action.payload.appName;
        state.appLabel = action.payload.appLabel;
      }
    );
  },
});

export default appStatusSlice.reducer;
