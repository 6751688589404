import { makeStyles } from "@zeos/theme";

export const useLoadMoreAccountsStyles = makeStyles({
  name: "LoadMoreAccounts"
})(theme => ({
  bottomSection: {
    width: "100%"
  },
  loadMoreButton: {
    justifyContent: "center",
    paddingBlock: theme.spacing(3)
  }
}));
