import { AppConfig, Me } from "@zeos/platform";

export const COMMON_TAGS = {
  ZALANDO_EMPLOYEE: "is_zalando_employee",
  CONSENT: "consent",
  COMPONENT: "component"
};

/**
 * Get the current active app that matches the url pathname and returns the app name
 * @param {AppConfig[]} apps Portal accessible apps
 */
export const getTracerComponentName = (
  apps: AppConfig[] = []
): string | undefined => {
  const activeApp = apps.find(app =>
    window.location.pathname.includes(app.pathPrefix)
  );
  return (activeApp || {}).name;
};

export const getIsZalandoEmployee = (me: Me = {} as Me): boolean => {
  const { principal_user_email: email } = me;
  return email ? email.includes("@zalando.") : false;
};
