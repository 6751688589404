import React, { FC } from "react";
import Box from "@zeos/components/Box";

type Props = {
  children?: React.ReactNode;
};

export const TopBar: FC<Props> = ({ children }) => (
  <Box
    component="header"
    position="sticky"
    top={0}
    display="flex"
    justifyContent="space-between"
    alignItems="center"
    px={5}
    py={3}
    bgcolor={theme => theme.palette.semantic.surface.default}
    zIndex={5}
    borderBottom={theme =>
      `0.5px solid ${theme.palette.semantic.interactive.surface.border.default}`
    }
  >
    {children}
  </Box>
);
