import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";
import { getServerSpanContext } from "./ObjectPropagator";
import { hasPerformanceApi } from "./utils";

let clientSpan: SpanImp | null = null;

/**
 * Get the cached client app or create a new client tracer span
 * as child of server tracer for the first app
 * @param {Tracer} tracer lightstep tracer
 */
export const getClientSpan = (tracer: Tracer): SpanImp => {
  if (clientSpan) {
    return clientSpan;
  }

  clientSpan = tracer.startSpan("render_client", {
    childOf: getServerSpanContext("portalSpan")
  }) as SpanImp;

  clientSpan.setTag("browser_performance_api_enabled", hasPerformanceApi());

  return clientSpan;
};

/**
 * @only For tests
 */
export const resetClientSpan = (): void => {
  clientSpan = null;
};
