export const isBFFRequest = (url: string): boolean => {
  return url.startsWith(import.meta.env.VITE_BFF_URL);
}

export const isGatewayRequest = (url: string): boolean => {
  return url.startsWith('/api');
}
export const requestShouldBeSigned = (url: string): boolean => {
  return isBFFRequest(url) || isGatewayRequest(url);
}

export const getRequestPath = (url: string): string => {
  return url.replace('/api/', '/');
}
