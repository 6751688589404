import React from "react";
import Typography from "@zeos/components/Typography";
import { FooterOption } from "./Dropdown.types";
import { useDropdownFooterStyles } from "./Dropdown.styles";

type DropdownFooterProps = {
  footerOptions?: FooterOption[];
  handleClose: () => void;
};

/**
 * Renders the dropdown options provided to us by the user
 *
 * @param {footerOptions} footerOptions - The list of footer options to be displayed in the drop-down
 * @param {handleClose} handleClose - The function that closes the drop-down
 *
 * @returns {React.ReactElement | null}
 */

export function DropdownFooter({
  footerOptions,
  handleClose
}: DropdownFooterProps): React.ReactElement | null {
  const { classes } = useDropdownFooterStyles();

  if (!footerOptions || footerOptions.length === 0) {
    return null;
  }

  return (
    <li>
      <div className={classes.dividerWrapper}>
        {footerOptions.map(({ title, onOptionSelected }) => (
          <div
            onClick={() => {
              handleClose();
              onOptionSelected();
            }}
            key={title}
            className={classes.footerOptionWrapper}
          >
            <Typography variant="helper" className={classes.footerText}>
              {title}
            </Typography>
          </div>
        ))}
      </div>
    </li>
  );
}
