import { makeStylesWithClassOverrides as makeStyles } from "@zeos/theme";

export const useUserProfileStyles = makeStyles({ name: "UserProfile" })(
  theme => ({
    wrapper: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),

      "& li:not([data-type=accounts-container])": {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        position: "relative",
        display: "flex",
        gap: theme.spacing(2),
        alignItems: "flex-start"
      }
    }
  })
);
