import {
  DateFormatter,
  DateFormatterOptions,
  NumberFormatter,
  NumberFormatterOptions,
  CurrencyFormatter,
  CurrencyDisplayEnum,
  CurrencyFormatterOptions,
  DurationFormatter
} from "./formatter";

interface FormatterFilterProps {
  [key: string]: string;
}

const dateFormatter = new DateFormatter();
const numberFormatter = new NumberFormatter();
const currencyFormatter = new CurrencyFormatter();
const durationFormatter = new DurationFormatter();

export default class FormatterFactory {
  /**
   * Transform i18n filter string to key: value object
   * for example style=long, notation=standard
   * transforms to { style: 'long', notation: 'standard' }
   * @param  {string} filter
   */
  private parseFilters(filter: string): FormatterFilterProps {
    return filter.split(",").reduce((acc: FormatterFilterProps, s) => {
      const [key, value = ""] = s.trim().split("=");

      acc[key] = value;

      return acc;
    }, {});
  }

  /**
   * Returns required options for DateFormatter
   * @param  {FormatterFilterProps}
   * @returns DateFormatterOptions
   */
  private parseDateOptions({
    notation,
    style
  }: FormatterFilterProps): DateFormatterOptions {
    return { style, notation };
  }

  /**
   * Returns required options for NumberFormatter
   * @param  {FormatterFilterProps}
   * @returns NumberFormatterOptions
   */
  private parseNumberOptions({
    precision,
    compact
  }: FormatterFilterProps): NumberFormatterOptions {
    return {
      precision: precision ? parseInt(precision, 10) : undefined,
      compact: compact === ""
    };
  }

  /**
   * Returns required options for CurrencyFormatter
   * @param  {FormatterFilterProps}
   * @returns CurrencyFormatterOptions
   */
  private parseCurrencyOptions({
    currency,
    display,
    compact,
    showCents
  }: FormatterFilterProps): CurrencyFormatterOptions {
    return {
      currency,
      display: display as CurrencyDisplayEnum,
      compact: compact === "",
      showCents: showCents !== "false"
    };
  }

  /**
   * Factory to return required formatter depends on filter
   * @param {Date | string} value
   * @param {string} i18n interpolation filter value
   * @returns string
   */
  public format(value: Date | string, filter: string): string {
    const filters = this.parseFilters(filter);

    if (value instanceof Date) {
      return dateFormatter.format(value, this.parseDateOptions(filters));
    }

    if ("currency" in filters) {
      return currencyFormatter.format(
        parseFloat(value),
        this.parseCurrencyOptions(filters)
      );
    }

    if ("number" in filters) {
      return numberFormatter.format(
        parseFloat(value),
        this.parseNumberOptions(filters)
      );
    }

    if ("duration" in filters) {
      return durationFormatter.format(parseFloat(value));
    }

    return value;
  }
}
