import { makeStylesWithClassOverrides } from "@zeos/theme";

const useItemIconStyles = makeStylesWithClassOverrides({ name: "ItemIcon" })(
  () => ({
    itemIcon: {
      width: 20,
      height: 20
    }
  })
);

export default useItemIconStyles;
