import { configureRefreshFetch } from "refresh-fetch";
import { AccessToken, OktaAuth } from "@okta/okta-auth-js";
import { getRequestPath, requestShouldBeSigned } from "util/requestFiltering";

const { fetch: originalFetch } = window;

const appendAPIUrl = (path: string): string => {
  if (path.startsWith("http")) {
    return path;
  }
  return `${import.meta.env.VITE_API_URL}${path}`;
}

/**
 * Error raised in case authorization failures
 */
class FetchAuthorizationError extends Error {
  status = 401;
}

export const fetchOverride = function (authClient: OktaAuth) {
  return async (resource: RequestInfo | URL, config?: RequestInit) => {
    const token = await authClient.tokenManager.get("accessToken") as AccessToken;
    const shouldRequestBeSigned = typeof resource === "string" && requestShouldBeSigned(resource);

    if (!token || !shouldRequestBeSigned) {
      return originalFetch(resource, config);
    }

    const normalizedUrl = getRequestPath(resource as string);

    return originalFetch(appendAPIUrl(normalizedUrl), {
      ...config,
      headers: {
        ...config?.headers,
        Authorization: `Bearer ${token?.accessToken}`,
      },
    });
  };
}

export const zeosFetch = (authClient: OktaAuth) => {
  const defaultFetchCall = fetchOverride(authClient);
  return configureRefreshFetch({
    fetch: (url: RequestInfo | URL, options?: RequestInit) =>
      defaultFetchCall(url, options)
        .then(response => {
          if (!response.ok && response.status === 401) {
            const authorizationSource = response.headers.get(
              "X-Authorization-Source"
            );
            throw new FetchAuthorizationError(
              `401 status response, should refresh the token, X-Authorization-Source: ${authorizationSource}`
            );
          }
          return response;
        })
        .catch(error => Promise.reject(error)),
    shouldRefreshToken: (error: FetchAuthorizationError) => error?.status === 401,
    refreshToken: (): any => authClient.token.renewTokens(),
  })
};
