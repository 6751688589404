import SpanImp from "lightstep-tracer/lib/imp/span_imp";

/**
 * Adds a log to parent span with
 * label as key and relative time as value
 *
 * The precision of the time is decreased to three
 * decimal points to make it increase readability
 *
 * @param relativeTime number
 * @param label  string
 * @param parent  Span
 */
export const logPerformanceMetric = (
  relativeTime: number,
  label: string,
  parent: SpanImp
): void => {
  parent.log({
    [label]: `${relativeTime.toFixed(3)}ms`
  });
};

/**
 * callback from the navigation performance event observer.
 * creates the first paint and first contentful paint event spans
 *
 * @param list PerformanceObserverEntryList
 * @param tracer Tracer
 * @param parent Span
 */
export const traceObservedNavigationEntries = (
  list: PerformanceObserverEntryList,
  parent: SpanImp
): void => {
  const navigationEntries =
    list.getEntries() as Array<PerformanceNavigationTiming>;

  navigationEntries.forEach(entry => {
    logPerformanceMetric(entry.responseStart, "time-to-first-byte", parent);
    logPerformanceMetric(entry.domInteractive, "time-to-interactive", parent);
  });
};

/**
 * callback from the paint performance event observer.
 * creates the first paint and first contentful paint event spans
 *
 * @param list PerformanceObserverEntryList
 * @param tracer Tracer
 * @param parent Span
 */
export const traceObservedPaintEntries = (
  list: PerformanceObserverEntryList,
  parent: SpanImp
): void => {
  // the array below is actually PerformancePaintTiming;
  // typescript does not recognize it
  const paintEntries = list.getEntries() as Array<PerformanceNavigationTiming>;

  paintEntries.forEach(entry =>
    logPerformanceMetric(entry.startTime, entry.name, parent)
  );
};

/**
 * starts the perf observers for navigation and paint
 *
 * @param tracer Tracer
 * @param parent Span
 */
export const startPerformanceObservers = (clientSpan: SpanImp): void => {
  try {
    const navigationPo = new PerformanceObserver(list =>
      traceObservedNavigationEntries(list, clientSpan)
    );
    navigationPo.observe({ type: "navigation", buffered: true });

    const paintPo = new PerformanceObserver(list =>
      traceObservedPaintEntries(list, clientSpan)
    );
    paintPo.observe({ type: "paint", buffered: true });
  } catch {
    // do nothing
  }
};
