import { closeParcel } from "./close-parcel";
import parcelCache from "./parcel-cache";

/**
 * Start hook is always called before the lifecycle
 * function is called. Here, we find parcels that are
 * initiated by the parcel that we requested to close. Then, those
 * we will call close parcel on all the child parcels. This operation
 * only checks for one level of parcels. However, because the the
 * unmount hook is attached to all the mounted parcels, this hook
 * will be recursively called for all the children parcels as well.
 */
export const unmountHook = {
  start: async (_phase: string, name: string): Promise<void> => {
    const childParcels = Object.values(parcelCache.parcelCache).filter(
      data => data.initiator === name
    );

    const closePromises = childParcels.map(parcel =>
      closeParcel(parcel.config.name)
    );

    await Promise.all(closePromises);
  }
};
