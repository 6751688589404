import SpanContextImp from "lightstep-tracer/lib/imp/span_context_imp";
import { Span, Tracer } from "opentracing";
import { ZBaseTracer } from "./types";

export class ZNoopSpan extends Span {
  /**
   * This value is necessary just in case we
   * return the tracer from Span
   */
  protected _internalTracer: Tracer;

  /**
   * This is necessary because we assign value
   * to context in getRootSpan function
   */
  public _ctx?: SpanContextImp;

  constructor(tracer: ZNoopTracer) {
    super();
    this._internalTracer = tracer;
  }

  /**
   * This is necessary to comply with lightstep tracer
   * implementation
   */
  beginMicros(): number {
    return 0;
  }

  /**
   * This is necessary to comply with lightstep tracer
   * implementation
   */
  setBeginMicros(): this {
    return this;
  }

  /**
   * This is necessary to comply with lightstep tracer
   * implementation
   */
  endMicros(): number {
    return 0;
  }

  /**
   * This is necessary to comply with lightstep tracer
   * implementation
   */
  setEndMicros(): this {
    return this;
  }

  /**
   * This value is necessary just in case we
   * return the tracer from Span
   */
  _tracer(): Tracer {
    return this._internalTracer;
  }

  /**
   * Needed to comply with lightstep tracer
   */
  getTags(): Record<string, any> {
    return {};
  }
}

export class ZNoopTracer extends Tracer implements ZBaseTracer {
  /**
   * We need to return ZNoopSpan; so that,
   * everything is disabled
   */
  protected _startSpan(): ZNoopSpan {
    return new ZNoopSpan(this);
  }

  /**
   * This is necessary to comply with ZTracer
   */
  setMe(): void {}

  /**
   * This is necessary to comply with ZTracer
   *
   * This function returns undefined as the me object
   * that is passed to tracing related features are all noop
   * when this tracer is being used
   */
  getMe(): undefined {
    return undefined;
  }

  /**
   * This is necessary to comply with ZTracer
   */
  setMeTagsToSpan(): void {}

  /**
   * Static value to determine tracer
   */
  // eslint-disable-next-line class-methods-use-this
  getTracerName(): string {
    return "znooptracer";
  }

  setTracingConsentEnabledPromise(): void {}
}
