import React, { ErrorInfo } from "react";

import { sentry } from "@zeos/platform";

/**
 * This HOC adds error boundary around
 * any passed component by setting up
 * error boundary logic and rendering the passed
 * component as a child
 *
 * @param Component Component to decorate
 */
export function withErrorBoundary<T>(
  Component: React.ComponentType<T>
): React.ComponentType<T> {
  return class RootErrorBoundary extends React.Component<
    T,
    { error: boolean }
  > {
    static getDerivedStateFromError() {
      return { error: true };
    }

    constructor(props: T) {
      super(props);

      this.state = { error: false };
    }

    componentDidCatch(error: Error, info: ErrorInfo) {
      console.error(error, info);
      sentry.captureError(error, info);
    }

    render() {
      const { error } = this.state;

      if (error) return null;

      return <Component {...this.props} />;
    }
  };
}
