import { Tracer } from "lightstep-tracer";
import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { getServerSpanContext } from "./ObjectPropagator";
import { hasPerformanceApi } from "./utils";
import { getPortalConfig } from "core/portal-config";

let appSpan: SpanImp | null = null;
let isFirstAppLoaded = false;

/**
 * Get the cached app span or create a new app tracer span
 * as child of server span
 * @param {Tracer} tracer lightstep tracer
 */
export const getAppSpan = (tracer: Tracer): SpanImp => {
  if (appSpan) {
    return appSpan;
  }

  if (!isFirstAppLoaded) {
    appSpan = tracer.startSpan("render_app", {
      childOf: getServerSpanContext("rootSpan")
    }) as SpanImp;
  } else {
    appSpan = tracer.startSpan("render_app") as SpanImp;
  }

  appSpan.setTag("browser_performance_api_enabled", hasPerformanceApi());
  // Dynamically retrieve and set the environment tag
  const environmentValue = getPortalConfig().environment;
  appSpan.setTag("environment", environmentValue);

  return appSpan;
};

/**
 * Clear the cached app span by resetting it to null
 */
export const clearAppSpan = (): void => {
  appSpan = null;
};

/**
 * Updates the first loaded app status to true
 */
export const setFirstLoadedApp = (): void => {
  isFirstAppLoaded = true;
};
