interface NumberFormatOptions {
  fractionalDigitals: number;
}

const defaultOptions = {
  fractionalDigitals: 2
};

/**
 * Polyfill for Intl.NumberFormat({ notation: 'compact' })
 * returns compact version of the value and uses round-down approach:
 * the number always rounds up (1_254_567 -> 1.3m, extra ~45k) in NumberFormat
 * we cannot use this approach for currency and need to provide our own implementation
 * it takes a required fractionalDigitals and rounds only fraction for 1_254_567 fraction is 254567
 * with fractionalDigitals = 1 it returns 254567 -> 200000, so 1_254_567 -> 1.2m
 * @param  {number} value
 * @param  {NumberFormatOptions=defaultOptions} {fractionalDigitals}
 * @returns string
 */
export function formatCompact(
  value: number,
  { fractionalDigitals }: NumberFormatOptions = defaultOptions
): string {
  const SI_SYMBOL = ["", "k", "m", "b", "t", "p", "e"];
  const tier = (Math.log10(Math.abs(value)) / 3) | 0;
  if (tier === 0) {
    const fixedValue = fractionalDigitals === 0 ? Math.floor(value) : value;
    return String(parseFloat(fixedValue.toFixed(fractionalDigitals)));
  }
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = value / scale;
  const divider = Math.pow(10, fractionalDigitals);
  const numberPart = Math.floor(scaled * divider) / divider;

  return numberPart + suffix;
}
