import React, { forwardRef } from "react";
import Tooltip from "@zeos/components/Tooltip";
import IconButton from "@zeos/components/IconButton";
// TODO: import from @zeos/components
// eslint-disable-next-line import/no-extraneous-dependencies
import Avatar from "@mui/material/Avatar";
import Typography from "@zeos/components/Typography";

interface AvatarButtonProps {
  handleClick: () => void;
  initials: string;
}

export const AvatarButton = forwardRef<HTMLDivElement, AvatarButtonProps>(
  ({ handleClick, initials }, ref) => {
    return (
      <Tooltip title="User" ref={ref}>
        <IconButton data-testid="userIcon" onClick={handleClick}>
          <Avatar
            sx={{
              bgcolor: "#2C0640",
              width: 32,
              height: 32
            }}
          >
            <Typography variant="body2">{initials}</Typography>
          </Avatar>
        </IconButton>
      </Tooltip>
    );
  }
);

AvatarButton.displayName = "AvatarButton";
