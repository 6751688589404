import React from "react";
import ItemIcon from "../ItemIcon";
import { DropdownMenuOption } from "./Dropdown.types";
import MenuItem from "@zeos/components/MenuItem";
import { useDropdownMenuOptionsStyles } from "./Dropdown.styles";
import { DropdownProps } from "./Dropdown";

type DropdownMenuProps = {
  dropdownOptions: DropdownMenuOption[];
} & Pick<DropdownProps, "handleClose">;

/**
 * Renders the dropdown options provided to us by the user
 *
 * @param {dropdownOptions} dropdownOptions - List of dropdown options to be provided by the user
 * @param {handleClose} handleClose - The function that closes the drop-down
 *
 * @returns {React.ReactElement}
 */
export function DropdownMenuOptions({
  dropdownOptions,
  handleClose
}: DropdownMenuProps): React.ReactElement {
  const { classes } = useDropdownMenuOptionsStyles();
  return (
    <>
      {dropdownOptions.map(
        ({ iconName, title, onOptionSelected, urlToMatch, disabled }) => (
          <MenuItem
            key={`${title}-${iconName}`}
            selected={
              window.location.href.includes(urlToMatch as string) || false
            }
            disabled={disabled}
            onClick={() => {
              onOptionSelected();
              handleClose();
            }}
            className={classes.listMenuItem}
          >
            {iconName && <ItemIcon iconName={iconName} />}
            <span>{title}</span>
          </MenuItem>
        )
      )}
    </>
  );
}
