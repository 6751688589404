import txtTermsAndConditions from "./content/terms-and-conditions.md";
import txtImprint from "./content/imprint.md";
import txtDataProcessingStatement from "./content/data-processing-statement.md";

interface Tab {
  label: string;
  content: string;
}

interface Tabs {
  [propName: string]: Tab;
}

export const tabs: Tabs = {
  "terms-and-conditions": {
    label: "Terms and Conditions",
    content: txtTermsAndConditions
  },
  "data-processing-statement": {
    label: "Data Processing Statement",
    content: txtDataProcessingStatement
  },
  imprint: {
    label: "Imprint",
    content: txtImprint
  }
};
