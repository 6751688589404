import React, { useCallback } from "react";
import ReactMarkdown from "react-markdown";

import { navigateTo } from "util/routing";

import {
  Typography,
  Link,
  AlertDialog as AlertDialogComponent
} from "@zeos/components";
import { useAppDispatch, useAppSelector } from "core/store/hooks";
import { ALERT_DIALOG_CLOSE } from "@zeos/platform";

export const AlertDialog: React.FC = () => {
  const alertDialogData = useAppSelector(state => state.alertDialog);
  const dispatch = useAppDispatch();

  const onClose = () => dispatch({ type: ALERT_DIALOG_CLOSE });
  const {
    alertDialogConfig: { description, onSubmit, onCancel, ...dialogProps },
    isOpen
  } = alertDialogData;

  const handleAlertDialogSubmit = useCallback(() => {
    requestAnimationFrame(() => onSubmit());
    onClose();
  }, [onClose, onSubmit]);

  const handleAlertDialogClose = useCallback(() => {
    requestAnimationFrame(() => onCancel?.());
    onClose();
  }, [onClose, onCancel]);

  const AlertDialogDescription = useCallback(
    () => (
      <ReactMarkdown
        components={{
          p: ({ children }) => (
            <Typography variant="body2">{children}</Typography>
          ),
          a: ({ href, target, children }) => (
            <Link
              variant="body2"
              href={href}
              target={target}
              onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                if (href && target !== "_blank") {
                  e.preventDefault();
                  navigateTo(href);
                }
              }}
            >
              {children}
            </Link>
          )
        }}
        linkTarget={href => {
          if (href.startsWith("http")) {
            return "_blank";
          }
          return "_self";
        }}
      >
        {description}
      </ReactMarkdown>
    ),
    [description]
  );

  return (
    <AlertDialogComponent
      {...dialogProps}
      open={isOpen}
      description={<AlertDialogDescription />}
      onSubmit={handleAlertDialogSubmit}
      onClose={handleAlertDialogClose}
      disableEnforceFocus
    />
  );
};
