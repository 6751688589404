import React from "react";
import { createRoot } from "react-dom/client";

import { RootTemplate } from "./templates";

export const PORTAL_TEMPLATE_CONTAINER_SELECTOR =
  "mc-portal-template-container";

/**
 * Mount root template
 *
 * Mounts root template into the main div
 * that is served from server using React
 */
export const mountRootTemplate = (): void =>
  createRoot(
    document.getElementById(PORTAL_TEMPLATE_CONTAINER_SELECTOR)!
  ).render(<RootTemplate />);
