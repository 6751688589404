import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";
import { getServerSpanContext } from "./ObjectPropagator";
import { convertRelativeTimeToServerTime } from "./utils";
import { COMMON_TAGS } from "./tag-utils";

let rootSpan: SpanImp | null = null;

/**
 * Creates new span for tracing bootstrap by using {tracer} in params as a child
 * of the {parent} span
 * @param tracer Tracer
 * @param parent SpanImp
 */
export const getRootSpan = (tracer: Tracer): SpanImp => {
  if (rootSpan) {
    return rootSpan;
  }

  /**
   * We can only send the span context from server to client
   * and typically OpenTracing supports adding the context as a
   * parent of a span. However, in  our case, we need to have the
   * started server span itself to be able to close it in the client.
   * That's why we need to recreate this span in client using the correct
   * start time and context (spanID and traceId). We already send these
   * values in PortalConfig. Additionally, we also inject all the tags of the
   * server span as OpenTracing baggage; so that, we can add
   * it to the recreated client span.
   */

  // Set start time
  rootSpan = tracer.startSpan("view_portal", {
    startTime: convertRelativeTimeToServerTime(0)
  }) as SpanImp;

  const context = getServerSpanContext("rootSpan");

  // Set span context
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  rootSpan._ctx = context;

  // Set Tags
  context?.forEachBaggageItem((key: string, value: any) => {
    rootSpan?.setTag(key, value);
  });

  rootSpan.setTag(COMMON_TAGS.CONSENT, "true");

  return rootSpan;
};

/**
 * @only For tests
 */
export const resetRootSpan = (): void => {
  rootSpan = null;
};
