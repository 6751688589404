import { Me, sentry } from "@zeos/platform";
import { getPortalConfig } from "../../portal-config";

import { getClientSpan } from "./clientSpan";
import { closeApplicableServerSpan } from "./closeApplicableServerSpan";
import { startPerformanceTracing } from "./performance";
import { getRootSpan } from "./rootSpan";
import { getServerSpan } from "./serverSpan";
import { getTracer } from "./tracer";

/**
 * Trace starters
 *
 * Traces all spans including performance ones,
 * close span
 */
export const startTracing = async (): Promise<void> => {
  const tracer = getTracer("web_portal");
  const clientSpan = getClientSpan(tracer);
  const serverSpan = getServerSpan(tracer);
  const rootSpan = getRootSpan(tracer);
  // beginMicros is used for debugging purposes
  // TODO: remove it after debugging
  rootSpan.setTag("beginMicros", rootSpan.beginMicros());
  clientSpan.setTag("beginMicros", clientSpan.beginMicros());
  serverSpan.setTag("beginMicros", serverSpan.beginMicros());
  // TODO: Debugging long trace spans, send to sentry if the client span time is inaccurate (more than 5 minutes)
  const { serverTimeOrigin } = getPortalConfig().monitoring.tracing;
  const isClientSpanTimeInaccurate =
    serverTimeOrigin &&
    clientSpan.beginMicros() &&
    clientSpan.beginMicros() - serverTimeOrigin > 300000000; // 300000000 is 5 minutes in microseconds
  if (isClientSpanTimeInaccurate) {
    clientSpan.setTag("inaccurateTime", true);
  }
  try {
    closeApplicableServerSpan(getPortalConfig(), tracer.getMe());
    // start the performance metrics spans
    startPerformanceTracing();
  } catch (e) {
    clientSpan.setTag("error", true);
    serverSpan.setTag("error", true);
    rootSpan.setTag("error", true);
  } finally {
    clientSpan.finish();
    serverSpan.finish();
    // endMicros is used for debugging purposes
    // TODO: remove it after debugging
    serverSpan.setTag("endMicros", serverSpan.endMicros());
    clientSpan.setTag("endMicros", clientSpan.endMicros());
    if (isClientSpanTimeInaccurate) {
      sentry.captureError(new Error("Client span time is inaccurate"), {
        extra: {
          serverTimeOrigin,
          clientSpanBeginMicros: clientSpan.beginMicros(),
          serverSpanBeginMicros: serverSpan.beginMicros(),
          rootSpanBeginMicros: rootSpan.beginMicros(),
          clientSpanEndMicros: clientSpan.endMicros(),
          serverSpanEndMicros: serverSpan.endMicros(),
          rootSpanEndMicros: rootSpan.endMicros()
        }
      });
    }
  }
};

/**
 * Sets me object to tracing
 *
 * Me object is used to set some user related tags such
 * as `zalando_employee`. This function must be called
 * immediately after the me object is fetched.
 *
 * @param me Me object
 */
export const setMeForTracing = (me: Me): void => {
  const tracer = getTracer("web_portal");
  const clientSpan = getClientSpan(tracer);
  const serverSpan = getServerSpan(tracer);
  const rootSpan = getRootSpan(tracer);

  tracer.setMe(me);
  tracer.setMeTagsToSpan(clientSpan);
  tracer.setMeTagsToSpan(serverSpan);
  tracer.setMeTagsToSpan(rootSpan);
};
