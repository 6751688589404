import React from "react";
import { createRoot } from "react-dom/client";

import { StatusTemplate } from "./templates";

export const APP_STATUS_SELECTOR = "app-status";

/**
 * Mount status template
 *
 * Mounts status template into the status div
 * that is served from server using React
 */
export const mountStatusTemplate = (): void =>
  createRoot(document.getElementById(APP_STATUS_SELECTOR)!).render(
    <StatusTemplate />
  );
