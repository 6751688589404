import { sanitize } from "util/string";
import { HotjarConfig } from "core/portal-config";
import { hotjarEnabled } from "core/trackers/tracker-status";

/**
 * Checks hotjar configuration and active status, then
 * it constructs hotjar javascript snippet and append it
 * to the document head
 *
 * @param config - Portal's hotjar configuration
 */
export const appendHotjarScript = (siteID: HotjarConfig["siteID"]): void => {
  const siteId = sanitize(siteID.toString());
  const hotjarScript = document.createElement("script");
  hotjarScript.type = "text/javascript";
  hotjarScript.text = `
      (function (h, o, t, j, a, r) {
        h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
        h._hjSettings = { hjid: ${siteId}, hjsv: 6 };
        a = o.getElementsByTagName('head')[0];
        r = o.createElement('script'); r.async = 1;
        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
        a.appendChild(r);
      })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
    `;
  try {
    document.head.appendChild(hotjarScript);
  } catch (error) {
    console.error(
      "[Hotjar] failed to append Hotjar javascript snippet to document head, Hotjar data will not be collected, caused by:",
      error
    );
  }
};

export const startHotjar = async (config: HotjarConfig): Promise<void> => {
  const enabled = await hotjarEnabled(config);
  if (!enabled) return;

  appendHotjarScript(config.siteID);
};
