/**
 * Parcel manifest error
 *
 * Used for errors that are thrown
 * during manifest loading
 */
export class ParcelManifestError extends Error {
  constructor(error: string | undefined) {
    super(error);
    this.name = "ParcelManifestError";
  }
}

/**
 * Parcel load error
 *
 * Used for errors that are thrown
 * when loading parcels using SystemJS
 */
export class ParcelLoadError extends Error {
  constructor(error: string | undefined) {
    super(error);
    this.name = "ParcelLoadError";
  }
}

/**
 * Parcel abort error
 *
 * Used for errors that are thrown
 * when open parcel is aborted due to multiple calls
 */
export class ParcelAbortError extends Error {
  constructor(error: string | undefined) {
    super(error);
    this.name = "ParcelAbortError";
  }
}
