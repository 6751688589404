import { PortalConfig } from "core/portal-config";
import { Me } from "@zeos/platform";
import { closeSpan } from "core/api";
import {
  tracingConfigEnabled,
  tracingConsentEnabled
} from "core/trackers/tracker-status";
import {
  COMMON_TAGS,
  getIsZalandoEmployee,
  getTracerComponentName
} from "./tag-utils";
import { convertSpanTimeOriginToServer } from "./utils";

/**
 * Closes the {render_portal} & {view_portal} spans by making
 * a call to the backend point close-span.
 *
 * It only fires the request if tracing is enabled at config
 * level but the user did not gave consent to lightstep tracing.
 *
 * @param portalConfig
 * @param me
 */
export async function closeApplicableServerSpan(
  portalConfig: PortalConfig,
  me?: Me
): Promise<void> {
  const isTracingConfigEnabled = tracingConfigEnabled(portalConfig.monitoring);
  const isTracingConsentEnabled = await tracingConsentEnabled();
  // If the tracing config is enabled but there is no consent given, call
  // close span API to close {render_portal} & {view_portal} spans.
  if (isTracingConfigEnabled && !isTracingConsentEnabled) {
    const component = getTracerComponentName(portalConfig.apps);
    const zalandoEmployee = me ? getIsZalandoEmployee(me) : false;
    const spanEndTime = convertSpanTimeOriginToServer(
      window.loadStartTime * 1000
    );
    const serverTags = {
      ...portalConfig.monitoring.tracing.baggage,
      [COMMON_TAGS.ZALANDO_EMPLOYEE]: zalandoEmployee,
      [COMMON_TAGS.CONSENT]: false,
      [COMMON_TAGS.COMPONENT]: component
    };
    closeSpan(portalConfig.monitoring.tracing, serverTags, spanEndTime);
  }
}
