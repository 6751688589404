import "systemjs";
import "systemjs/dist/extras/amd";
import "systemjs/dist/extras/named-register";
import "import-map-overrides";
import { sentry, Toggle } from "@zeos/platform";
import { bootstrap } from "core/bootstrap";
import { initAuth } from "core/auth";
import { appendImportMap } from "util/import-map";

const getConfiguration = () => {
  return Promise.all([
    fetch(`${import.meta.env.VITE_BFF_URL}/config`).then(response =>
      response.json()
    ),
    fetch(`${import.meta.env.VITE_BFF_URL}/apps`).then(response =>
      response.json()
    ),
    fetch(
      `${
        import.meta.env.VITE_API_URL
      }/api/portals/fulfillment-b2b-portal/enabled-feature-toggles`
    ).then(response => response.json())
  ]);
};

// TODO move to platform package

async function loadConfiguration() {
  return getConfiguration().then(([configuration, apps, toggles]) => {
    const portalConfig = configuration.portalConfig ?? {};

    const enabledFeatureToggles = toggles.enabled_features as Toggle[];

    window.PortalConfig = {
      ...portalConfig,
      apps,
      enabledFeatureToggles
    };
    appendImportMap([...apps, ...portalConfig.parcels]);
  });
}

initAuth(() => loadConfiguration().then(bootstrap)).catch(error => {
  console.error("Error initializing shell", error);
  sentry.captureError(error);
});
