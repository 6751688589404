import { getLoadPath, getPortalLoadStatus } from "../core/portal-config";
import { getAppNameByPathSegments, NavigationTree } from "./navigation-tree";

export const navigateTo = (url: string): void => {
  window.history.pushState(null, "", url);
  const popStateEvent = new PopStateEvent("popstate", undefined);
  dispatchEvent(popStateEvent);
};

export function pathMatchPrefix(path: string, prefix: string): boolean {
  return Boolean(prefix) && path.startsWith(prefix);
}

export function pathMatchRegex(path: string, regex: RegExp): boolean {
  return regex.test(path);
}

export function getActiveAppName(
  navigationTree: NavigationTree,
  location: Location
): string | undefined {
  // "/sales/growth" split by the slash will end up in the array ["", "sales","growth"]
  // so, we use shift to remove the first entry i.e. empty string.
  const pathSegments = location.pathname.split("/");
  pathSegments.shift();
  return getAppNameByPathSegments(pathSegments, navigationTree);
}

// we have this function as a temporary solution. Currently, our user scope
// validation is done in the express server via keycloak but we don't have a
// proper validation process in the front-end. We will come back to it soon.
export function reloadTo(url: string): void {
  window.location.href = url;
}

/**
 * Function which returns if there was a error when the portal was first loaded.
 *
 * @param currentLocation
 */
export function isPortalLoadError(currentLocation: Location): boolean {
  const portalLoadStatus = getPortalLoadStatus();
  const isCurrentLocation = getLoadPath() === currentLocation.pathname;
  return isCurrentLocation && !!portalLoadStatus && portalLoadStatus >= 400;
}
