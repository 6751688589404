import { SnackbarOptions } from "@zeos/platform";

export const snackbarActions = {
  SNACKBAR_ADD: "__portal.snackbar.add__",
  SNACKBAR_REMOVE: "__portal.snackbar.remove__"
} as const;

export interface SnackbarState {
  list: (SnackbarOptions & { id: string })[];
}

export interface SnackbarAction {
  type: string;
}

export interface SnackbarActionAdd extends SnackbarAction {
  payload: SnackbarOptions & { id: string };
}

export interface SnackbarActionRemove extends SnackbarAction {
  id: string;
}

export const initialState: SnackbarState = {
  list: []
};

const isSnackbarAdd = (action: SnackbarAction): action is SnackbarActionAdd =>
  action.type === snackbarActions.SNACKBAR_ADD;

const isSnackbarRemove = (
  action: SnackbarAction
): action is SnackbarActionRemove =>
  action.type === snackbarActions.SNACKBAR_REMOVE;

export default (
  state: SnackbarState = initialState,
  action: SnackbarAction
): SnackbarState => {
  if (isSnackbarAdd(action)) {
    return {
      ...state,
      list: [...state.list, action.payload]
    };
  }
  if (isSnackbarRemove(action)) {
    return {
      ...state,
      list: [...state.list.filter(item => item.id !== action.id)]
    };
  }

  return state;
};
