import { ConnectionType, ResourceEntry } from "./types";

/**
 * Determines the users connection speed
 *
 * Based on ADR Record #4
 * https://github.bus.zalan.do/merchant-platform/quasar-adrs/blob/master/web-portal/04-user-connection-type.md
 *
 * @param entries Array<ResourceEntry>
 * @return ConnectionType
 */
export const determineConnectionType = (
  entries: Array<ResourceEntry>
): ConnectionType => {
  const thresholds: Array<{ type: ConnectionType; threshold: number }> = [
    { type: "fast", threshold: 320 },
    { type: "average", threshold: 550 },
    { type: "slow", threshold: 2000 }
  ];

  const speeds = entries.map(({ startTime, endTime, transferSize }) => {
    const duration = endTime - startTime;
    return transferSize / duration / 1000; // megabytes / second
  });

  const sum = speeds.reduce((prev, curr) => prev + curr, 0);
  const avg = sum / speeds.length;
  const element = thresholds.find(th => avg <= th.threshold);

  return element ? element.type : "very-slow";
};
