import parcelCache from "./parcel-cache";

/**
 * Close parcel
 *
 * Unmount parcel single spa object and update parcel cache
 * to set parcel single-spa object to undefined
 *
 * @param name Parcel name
 */
export const closeParcel = async (name: string): Promise<void> => {
  const parcel = parcelCache.getParcelData(name);

  if (parcel && parcel.singleSpaParcel) {
    await parcel.singleSpaParcel.unmount();

    parcelCache.setParcelData(name, {
      ...parcel,
      singleSpaParcel: undefined,
      initiator: ""
    });
  }
};
