import SpanImp from "lightstep-tracer/lib/imp/span_imp";
import { Tracer } from "opentracing";

export const loadEventFallback = (
  tracer: Tracer,
  parent?: SpanImp,
  cb?: (span: SpanImp) => void
): void => {
  window.addEventListener(
    "load",
    () => {
      const span = tracer.startSpan("load_resources", {
        childOf: parent,
        startTime: window.loadStartTime * 1000
      }) as SpanImp;
      span.setTag("collected_from", "load_event");
      const endTime = window.loadEndTime || new Date().getTime();
      span.finish(endTime * 1000);
      if (cb) {
        cb(span);
      }
    },
    // only run this event listener ONCE
    { once: true }
  );
};
