import { getPortalConfig } from "core/portal-config";

export type ServicesName =
  (typeof SERVICES_CONSENT_NAME_MAP)[keyof typeof SERVICES_CONSENT_NAME_MAP];

/**
 * Service consent name map
 */
export const SERVICES_CONSENT_NAME_MAP = {
  GTM: "Google Analytics for ZEOS Portal",
  SENTRY: "Sentry for ZEOS Portal",
  SCALYR: "Scalyr for ZEOS Portal",
  APPCUES: "Appcues for ZEOS Portal",
  HOTJAR: "Hotjar for ZEOS Portal",
  TRACING: "Lightstep for ZEOS Portal"
} as const;

/**
 * Returns the consent integration active status
 */
export const isConsentActive = (): boolean => {
  const portalConfig = getPortalConfig();
  return (
    (portalConfig.consent &&
      portalConfig.consent.active &&
      Boolean(window.UC_UI)) ||
    false
  );
};

/**
 * Gets the user consent status for a specific third-party service using service ID
 *
 * @param serviceId - Service tracer id
 */
export const getServiceConsent = (
  serviceId: ServicesName
): Promise<boolean> => {
  const UC_UI = window.UC_UI;

  if (UC_UI === undefined) {
    return Promise.resolve(false);
  }

  if (UC_UI.areAllConsentsAccepted && UC_UI.areAllConsentsAccepted()) {
    return Promise.resolve(true);
  }

  const service = UC_UI.getServicesBaseInfo?.().find(
    data => data.name === serviceId
  );

  if (service) {
    return Promise.resolve(service.consent.status);
  } else {
    console.warn(`[Consent] Cannot get any consent info from ${serviceId}`);
    return Promise.resolve(false);
  }
};

/**
 * Toggle the consent data preferences modal for the user
 */
export const toggleConsentDataPreferences = (): void => {
  window.UC_UI?.showSecondLayer?.();
};
